/** @jsxImportSource @emotion/react */
import { TableCell, TableRow, Alert, Button, Link } from "@mui/material";
import {
  GridCollection,
  GridFilter,
  useAccountService,
  useNotificationProvider,
  useOrganisationService,
} from "core/hooks";
import { useEffect, useState } from "react";
import {
  FilterOrganisationAllocationsModel,
  FilterOrganisationAllocationsModelGridCollection,
} from "core/webapi";
import { GridCpt } from "components/grid";
import { AxiosPromise } from "axios";
import { useSetRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { ConfirmDialogCpt } from "components/common";
import { EditUsersDialogFormCpt } from "components/account";

interface OrganisationAllocationsGridProps {
  organisationId: number;
  refresh: (filter: any) => AxiosPromise<GridCollection<any>>;
  data: FilterOrganisationAllocationsModelGridCollection;
  defaultFilter: GridFilter;
  shouldUpdateUrl?: boolean;
}

export const OrganisationAllocationsGridCpt = (
  props: OrganisationAllocationsGridProps
) => {
  const {
    refresh,
    data,
    defaultFilter,
    shouldUpdateUrl,
    organisationId,
  } = props;
  const { success } = useNotificationProvider();
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);

  const [internalFilter, setInternalFilter] = useState(defaultFilter);
  const { removeOrganisationUser } = useOrganisationService();
  const { resendInvite } = useAccountService();

  const [currentUser, setCurrentUser] = useState(
    new FilterOrganisationAllocationsModel()
  );
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [editUserDialog, setEditUserDialog] = useState(false);

  const closeDeleteUserDialog = async (answer: boolean) => {
    setDeleteUserDialog(false);
    if (answer) {
      setSharedIsLoading(true);
      try {
        await removeOrganisationUser({
          allocationId: currentUser.allocationId,
        });
        success("User successfully deleted");
        // force grid refresh
        setInternalFilter((prevFilter) => ({
          ...defaultFilter,
          ...prevFilter,
        }));
      } finally {
        setSharedIsLoading(false);
        setCurrentUser(new FilterOrganisationAllocationsModel());
      }
    }
  };

  const closeEditUserDialog = async (answer: boolean) => {
    setEditUserDialog(false);
    if (answer) {
      setInternalFilter((prevFilter) => ({
        ...defaultFilter,
        ...prevFilter,
      }));
    }
  };

  const handleDeleteUser = (user: FilterOrganisationAllocationsModel) => {
    setCurrentUser(user);
    setDeleteUserDialog(true);
  };

  const handleEditUser = (user: FilterOrganisationAllocationsModel) => {
    setCurrentUser(user);
    setEditUserDialog(true);
  };

  const handleResendInvite = async (
    user: FilterOrganisationAllocationsModel
  ) => {
    setSharedIsLoading(true);
    try {
      await resendInvite({
        userId: user.userId,
        organisationId: organisationId,
        roleId: internalFilter.roleId,
      });
      success("Invite successfully sent");
    } finally {
      setSharedIsLoading(false);
    }
  };

  const headers = [
    { title: "Name", sortBy: "user.userDetail.firstName" },
    { title: "Email", sortBy: "user.email" },
    { title: "Identifier", sortBy: "organisationSpecificIdentifier" },
    { title: "Status" },
    { title: "", align: "right", size: "small" },
  ];

  useEffect(() => {
    setInternalFilter(defaultFilter);
  }, [defaultFilter]);

  return (
    <>
      <GridCpt
        refresh={refresh}
        defaultFilter={internalFilter}
        headers={headers}
        shouldUpdateUrl={!!shouldUpdateUrl}
      >
        {data.items && data.items.length > 0 ? (
          data.items?.map((item) => (
            <TableRow key={item.allocationId}>
              <TableCell>
                {item.firstName} {item.lastName}
              </TableCell>
              <TableCell>
                <Link href={`mailto:${item.email}`} underline="always">
                  {item.email}
                </Link>
              </TableCell>
              <TableCell>{item.organisationSpecificIdentifier}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell align="right" width="300">
                {item.canGetAnInvite ? (
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={() => handleResendInvite(item)}
                    sx={{
                      fontFamily: "Roboto",
                    }}
                  >
                    Resend invite
                  </Button>
                ) : null}
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => handleEditUser(item)}
                  sx={{
                    fontFamily: "Roboto",
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => handleDeleteUser(item)}
                  disabled={item.isDeleted}
                  sx={{
                    fontFamily: "Roboto",
                  }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <Alert severity="info">No users have been registered yet</Alert>
            </TableCell>
          </TableRow>
        )}
      </GridCpt>
      <ConfirmDialogCpt
        title="Are you sure?"
        description="Are you sure you want to delete this user?"
        open={deleteUserDialog}
        close={closeDeleteUserDialog}
      />
      <EditUsersDialogFormCpt
        open={editUserDialog}
        close={closeEditUserDialog}
        organisationId={organisationId}
        userId={currentUser.userId}
      />
    </>
  );
};
