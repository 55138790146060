/** @jsxImportSource @emotion/react */
import { PageContainerCpt } from "components/common";
import { useEffect, useState } from "react";
import { useAuth } from "core/hooks";
import { OrganisationAllocationsCpt } from "components/organisation";
import { useNavigate, useParams } from "react-router-dom";

export const OrganisationAllocationsGridView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const auth = useAuth();
  const [payload] = useState(() => auth.getPayload());
  const [organisationId, setOrganisationId] = useState(
    Number(payload.organisationId)
  );
  const [tab, setTab] = useState(() => Number(params.tab));

  const handleTabChange = (newValue: number) => {
    navigate(`/admin/organisation/users/${newValue}`, { replace: true });
  };

  useEffect(() => {
    setOrganisationId(Number(payload.organisationId));
  }, [payload.organisationId]);

  useEffect(() => {
    const internalTab = Number(params.tab);
    setTab(internalTab);
  }, [params.tab]);

  return (
    <PageContainerCpt>
      <OrganisationAllocationsCpt
        tab={tab}
        organisationId={organisationId}
        onTabChanged={handleTabChange}
        shouldUpdateUrl={true}
      />
    </PageContainerCpt>
  );
};
