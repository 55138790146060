/** @jsxImportSource @emotion/react */
import {
  IconButton,
  Toolbar,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/List";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { config } from "core/constants";
import { useRecoilState } from "recoil";
import { openDrawerState } from "core/atoms";
import { ReactComponent as Logo } from "styles/logo_unidoodle_white.svg";
import { makeStyles } from "@mui/styles";
import * as colors from "styles/colors";
import { useAuth, usePermission } from "core/hooks";
import { useEffect, useState } from "react";

const useStyles = makeStyles({
  icon: {
    color: colors.palette.palette.blue.contrastText,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: config.style.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const DrawerCpt = () => {
  const { getPayload } = useAuth();
  const [open, setOpen] = useRecoilState(openDrawerState);
  const { isSuperUser, isOrganisationAdmin } = usePermission();
  const [payload, setPayload] = useState(() => getPayload());
  const classes = useStyles();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setPayload(getPayload());
  }, [getPayload]);

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            flexGrow: 1,
            textAlign: "center",
            position: "relative",
            display: "block",
            lineHeight: 0,
          }}
        >
          <Logo
            css={{
              maxWidth: "191px",
            }}
          />
        </Typography>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon className={classes.icon} />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {isOrganisationAdmin() ? (
          <>
            <ListItemButton href={`/admin/licence/${payload.organisationId}/0`}>
              <ListItemIcon>
                <AdminPanelSettingsIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Licence" />
            </ListItemButton>
            <ListItemButton href="/admin/organisation/users/0">
              <ListItemIcon>
                <PeopleIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItemButton>
          </>
        ) : null}
        {isSuperUser() ? (
          <>
            <ListItemButton href="/admin/organisations">
              <ListItemIcon>
                <ListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Organisations" />
            </ListItemButton>
            <ListItemButton href="/admin/users">
              <ListItemIcon>
                <PeopleIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItemButton>
          </>
        ) : null}
      </List>
    </Drawer>
  );
};
