import { useEffect, useState } from "react";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";
import { appFormComponent } from "core/providers";

export const DtDateTimePickerInputCpt = ({
  model,
  setModel,
  prop,
  label,
  type = "date",
  fullWidth = false,
  onChange = (e: any) => {},
  step,
  disabled,
}: {
  model: any;
  setModel: Function;
  prop: string;
  label: string;
  type?: "date" | "time" | "datetime-local";
  fullWidth?: boolean;
  onChange?: Function;
  step?: number;
  disabled?: boolean;
}) => {
  const { errorMessage, clearErrorMessage } = appFormComponent();
  const [error, setError] = useState(() => errorMessage(model, prop));

  useEffect(() => {
    setError(errorMessage(model, prop));
  }, [setError, errorMessage, model, prop]);

  const updateModel = (e: any) => {
    let internalModel = Object.assign({}, model);
    internalModel[prop] = e.target.value;
    clearErrorMessage(internalModel, prop);
    setModel(internalModel);
    onChange(e);
  };

  return (
    <FormControl variant="standard" error={!!error} fullWidth={fullWidth}>
      {!label ? null : (
        <InputLabel shrink htmlFor={prop}>
          {label}
        </InputLabel>
      )}
      <Input
        error={!!error}
        type={type}
        fullWidth={fullWidth}
        id={prop}
        name={prop}
        onChange={updateModel}
        value={model[prop] || ""}
        size="small"
        disabled={disabled}
      />
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
