import {  userServiceState, accountServiceState, organisationServiceState } from "core/atoms";
import { useRecoilValue } from "recoil";

export const useAccountService = () => {
    const api = useRecoilValue(accountServiceState);
    return api;
}

export const useOrganisationService = () => {
    const api = useRecoilValue(organisationServiceState);
    return api;
}

export const useUserService = () => {
    const api = useRecoilValue(userServiceState);
    return api;
}