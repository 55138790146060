/** @jsxImportSource @emotion/react */
import { Button, Typography } from "@mui/material";
import { useAccountService } from "core/hooks";
import { useParams } from "react-router-dom";
import { sharedLoadingState } from "core/atoms";
import { useSetRecoilState } from "recoil";
import { useNotificationProvider } from "core/hooks";
import {
  RegisteringContainerCpt,
  CompleteRegistrationImageBoxCpt,
} from "components/account";
import * as colors from "styles/colors";

export const ConfirmationEmailSentView = () => {
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const params = useParams();
  const { sendConfirmationEmail } = useAccountService();
  const { success } = useNotificationProvider();
  const textSx = {
    color: colors.palette.palette.secondary.main,
    fontSize: 18,
    textAlign: "start",
  };

  const send = async (event: any) => {
    event.preventDefault();
    setSharedIsLoading(true);
    await sendConfirmationEmail({ userId: Number(params.userId) })
      .then(() => success("Email has been sent"))
      .finally(() => setSharedIsLoading(false));
  };

  return (
    <RegisteringContainerCpt
      title={"Check your email"}
      subTitle={`We have sent an email with a confirmation link to your email
      address. In order to continue your registration, please click the
      confirmation link.`}
      sideBox={<CompleteRegistrationImageBoxCpt />}
    >
      <Typography
        variant="subtitle1"
        component="h2"
        sx={{
          marginTop: 2,
          ...textSx,
        }}
      >
        Didn't receive an email from us? Check your spam folder, or resend email
        below.
      </Typography>
      <Typography
        variant="subtitle1"
        component="h2"
        sx={{
          marginTop: 2,
          ...textSx,
        }}
      >
        Still experiencing issues? Contact us at info@unidoodle.com - we'll be
        happy to help
      </Typography>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={send}
        sx={{
          marginTop: 4,
        }}
      >
        Resend confirmation email
      </Button>
    </RegisteringContainerCpt>
  );
};
