import _ from "lodash";
import { useState } from "react";
import { useAuth } from './auth';

export const usePermission = () => {
    const { isAuthenticated, getPayload } = useAuth();
    const [payload] = useState(() => getPayload());

    const isInRole = (roleName: string) => {
        if (!isAuthenticated() || !payload || !payload.userRoles) {
            return false;
        }

        if (typeof payload.userRoles === 'string') {
            return payload.userRoles === roleName;
        }

        return _.includes(payload.userRoles, roleName);
    };

    return {
        isInRole: (roleName: string) => isInRole(roleName),
        isAdmin: () => isInRole("Admin"),
        isSuperUser: () => isInRole("SuperUser") || isInRole("Admin"),
        isOrganisationAdmin: () => isInRole("OrganisationAdmin"),
        isPresenter: () => isInRole("Presenter"),
        isParticipant: () => isInRole("Participant"),
    };
}