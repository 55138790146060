import * as colors from "styles/colors";
import {
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { sharedLoadingState, sharedSnackbarState } from "core/atoms";
import { RegisteringLayout } from "./RegisteringLayout";
import { LoginLayout } from "./LoginLayout";
import { AuthenticatedLayout } from "./AuthenticatedLayout";
import { NotificationProviderSetter, AuthProviderSetter } from "core/constants";
import { isAuthenticated, useAuth } from "core/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const AppLayout = () => {
  const isLoading = useRecoilValue(sharedLoadingState);
  const snackbarState = useRecoilValue(sharedSnackbarState);
  const setSnackbarState = useSetRecoilState(sharedSnackbarState);
  const { hasCompletedRegistration } = useAuth();
  const [layout, setLayout] = useState(null as any);
  const location = useLocation();

  const handleSnackbarClose = () => {
    setSnackbarState({
      open: false,
      message: "",
      severity: "info",
    });
  };

  useEffect(() => {
    if (location.pathname.startsWith("/register")) {
      setLayout(<RegisteringLayout />);
    } else if (location.pathname.startsWith("/account")) {
      setLayout(<LoginLayout />);
    } else if (isAuthenticated()) {
      setLayout(<AuthenticatedLayout />);
    } else {
      setLayout(<RegisteringLayout />);
    }
  }, [hasCompletedRegistration, location]);

  return (
    <>
      <NotificationProviderSetter />
      <AuthProviderSetter />
      <Backdrop
        sx={{
          color: colors.palette.palette.secondary.contrastText,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: `${colors.palette.palette.secondary.main}90`,
        }}
        open={isLoading}
        invisible={true}
      >
        <CircularProgress />
      </Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState.severity as AlertColor}
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
      {layout}
    </>
  );
};
