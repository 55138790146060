/** @jsxImportSource @emotion/react */
import { Container, Typography, Grid, Link, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { sharedLoadingState } from "core/atoms";
import { useSetRecoilState } from "recoil";
import { GetOrganisationRegistrationCompletedForCurrentUserModel } from "core/webapi";
import { useAccountService, useAuth } from "core/hooks";
import * as colors from "styles/colors";
import { CardBodyCpt, CardSubTitleCpt, CardTextCpt } from "components/common";

export const FieldTitle = ({ children }: { children: any }) => {
  return (
    <CardSubTitleCpt
      sx={{
        color: colors.palette.palette.secondary.main,
      }}
    >
      {children}
    </CardSubTitleCpt>
  );
};

export const BoxTitle = ({ children }: { children: any }) => {
  return (
    <CardSubTitleCpt
      sx={{
        color: colors.palette.palette.blue.main,
        fontSize: 18,
      }}
    >
      {children}
    </CardSubTitleCpt>
  );
};

export const RegistrationCompletedView = () => {
  const { getPayload } = useAuth();
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const [payload, setPayload] = useState(() => getPayload());
  const { getOrganisationRegistrationCompletedForCurrentUser } =
    useAccountService();
  const [model, setModel] = useState(
    () => new GetOrganisationRegistrationCompletedForCurrentUserModel()
  );

  useEffect(() => {
    const loadLookups = async () => {
      setSharedIsLoading(true);
      try {
        const resp = await getOrganisationRegistrationCompletedForCurrentUser();
        setModel(resp.data);
      } finally {
        setSharedIsLoading(false);
      }
    };
    loadLookups();
  }, [getOrganisationRegistrationCompletedForCurrentUser, setSharedIsLoading]);

  useEffect(() => {
    setPayload(getPayload());
  }, [getPayload]);

  return (
    <Container maxWidth={false}>
      <Grid
        sx={{
          marginX: 15,
        }}
        container
        spacing={2}
        rowSpacing={2}
        direction="row"
        justifyContent="top"
        alignItems="stretch"
      >
        <Grid xs={12} item>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              marginTop: "20px",
              textAlign: "start",
            }}
          >
            Registration Confirmation
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            variant="subtitle1"
            component="h2"
            sx={{
              marginTop: 3,
              color: colors.palette.palette.secondary.main,
              fontSize: 18,
              textAlign: "start",
            }}
          >
            Thanks for signing up to UniDoodle. You will receive more
            information via email shortly.
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={8}
          item
          sx={{
            paddingX: "5px !important",
          }}
        >
          <CardBodyCpt>
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="top"
              alignItems="stretch"
            >
              <Grid xs={12} item>
                <BoxTitle>Account Information</BoxTitle>
              </Grid>
              <Grid xs={12} md={6} item>
                <FieldTitle>Customer name</FieldTitle>
                <CardTextCpt>{model.userName}</CardTextCpt>
              </Grid>
              <Grid xs={12} md={6} item>
                <FieldTitle>Organisation name</FieldTitle>
                <CardTextCpt>{model.organisationName}</CardTextCpt>
              </Grid>
              <Grid xs={12} md={6} item>
                <FieldTitle>Email</FieldTitle>
                <CardTextCpt>{model.userEmail}</CardTextCpt>
              </Grid>
              <Grid xs={12} md={6} item>
                <FieldTitle>License purchased</FieldTitle>
                <CardTextCpt>
                  {model.presenters} presenters / {model.participants}{" "}
                  participants
                </CardTextCpt>
              </Grid>
            </Grid>
          </CardBodyCpt>
        </Grid>
        <Grid
          xs={12}
          md={4}
          item
          sx={{
            paddingRight: 25,
          }}
        >
          <CardBodyCpt
            backgroundColor={colors.palette.palette.white.main}
            sx={{
              border: `1px solid ${colors.palette.palette.lightBackground.main}`,
              height: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="top"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <BoxTitle>Need help?</BoxTitle>
              </Grid>
              <Grid item xs={12}>
                <FieldTitle>Contact us:</FieldTitle>
              </Grid>
              <Grid item xs={12}>
                <CardTextCpt>
                  <Link href="mailto:info@unidoodle.com">
                    info@unidoodle.com
                  </Link>
                </CardTextCpt>
              </Grid>
              <Grid item xs={12}>
                <CardTextCpt>
                  <Link href="tel:+35311234567">01 123 4567</Link>
                </CardTextCpt>
              </Grid>
            </Grid>
          </CardBodyCpt>
        </Grid>
        <Grid
          xs={12}
          md={8}
          item
          sx={{
            paddingX: "5px !important",
          }}
        >
          <CardBodyCpt>
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="top"
              alignItems="center"
            >
              <Grid
                xs={12}
                item
                sx={{
                  textAlign: "center",
                }}
              >
                <BoxTitle>Get Started</BoxTitle>
              </Grid>
              <Grid
                xs={12}
                item
                sx={{
                  textAlign: "center",
                }}
              >
                <CardTextCpt>
                  As an early adopter of UniDoodle, you do not need to pay for
                  your first license period. Our support staff will be in touch
                  at a future date to discuss pricing plans and the needs of
                  your organisation.
                </CardTextCpt>
              </Grid>

              <Grid
                xs={12}
                item
                sx={{
                  textAlign: "center",
                }}
              >
                <CardTextCpt>
                  To get started, head to your dashboard below.
                </CardTextCpt>
              </Grid>

              <Grid
                xs={12}
                item
                sx={{
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  href={`/admin/licence/${payload.organisationId}/0`}
                  sx={{
                    paddingX: 15,
                  }}
                >
                  Go to portal
                </Button>
              </Grid>
            </Grid>
          </CardBodyCpt>
        </Grid>
      </Grid>
    </Container>
  );
};
