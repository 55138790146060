import { useSetRecoilState } from "recoil";
import { sharedSnackbarState } from "core/atoms";



export const useNotificationProvider = () => {
    const setSnackbarState = useSetRecoilState(sharedSnackbarState);

    return {
        error(message: string) {
            setSnackbarState({
                open: true,
                message: message,
                severity: "error",
            });
        },
        warning(message: string) {
            setSnackbarState({
                open: true,
                message: message,
                severity: "warning",
            });
        },
        info(message: string) {
            setSnackbarState({
                open: true,
                message: message,
                severity: "info",
            });
        },
        success(message: string) {
            setSnackbarState({
                open: true,
                message: message,
                severity: "success",
            });
        },
    };
}