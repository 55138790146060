/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material";
import * as colors from "styles/colors";
export const PageTitleCpt = ({
  children,
  onClick,
}: {
  children: any;
  onClick?: (e: any) => {};
}) => {
  return (
    <Typography
      onClick={onClick}
      variant="h1"
      component="h1"
      sx={{
        fontFamily: "'Roboto', sans-serif;",
        backgroundColor: colors.palette.palette.lightBackground.main,
        color: colors.palette.palette.blue.main,
        fontWeight: 400,
        fontSize: "24px",
        width: "100%",
        paddingLeft: 7,
        paddingY: 4,
        cursor: !onClick ? "text" : "pointer",
      }}
    >
      {children}
    </Typography>
  );
};
