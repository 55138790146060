import { AxiosPromise } from "axios";
import { sharedLoadingState } from "core/atoms";
import _, { each, isNaN, toNumber, debounce } from "lodash";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { GridCollection, GridFilter, GridPagination } from "./grid.models";

const clearFalsyParams = (filter: GridFilter) => {
    return _.pickBy(filter, (p) => !!p) as GridFilter;
};

export function useGrid(defaultFilter: GridFilter, refresh: (filter: GridFilter) => AxiosPromise<GridCollection<any>>, shouldUpdateUrl = true) {
    const [grid, setGrid] = useState<GridCollection<object>>(new GridCollection<object>());
    const setSharedIsLoading = useSetRecoilState(sharedLoadingState);

    const updateUrl = useCallback((f: GridFilter) => {
        if (!shouldUpdateUrl) return;
        const definedParams = clearFalsyParams(f);
        const url = new URL(window.location.href.replace(window.location.search, ""));
        _.each(definedParams, (param, paramName) => {
            url.searchParams.set(paramName, param)
        });
        window.history.pushState({}, "", url);
    }, [shouldUpdateUrl]);
    /**
        * Creates a new grid filter. Uses page 1 and page size 20 as defaults.
        * Then it applies the initial filter passed to the GridCpt by the calling Cpt.
        * Then applies any potential grid filter overrides if the argument is
        * passed to the function.
        * @param overrides Grid filter overrides to apply to the base filter.
        */
    const filterFactory = useCallback((overrides?: GridFilter) => {
        const filter = new GridFilter({
            page: 1,
            pageSize: 20,
            ...overrides,
        });
        return filter;
    }, []);

    /**
      * Reads current URL query params and creates a filter object using them
      * as an override over the default grid filter factory values.
      */
    const getFilter = useCallback(() => {
        function qsParse() {
            const queryParams = new URLSearchParams(window.location.search);
            // These are standard, expected params and defaults need to be set
            const filter: GridFilter = {
                page: queryParams.get("page") ? Number(queryParams.get("page")) : defaultFilter.page,
                pageSize: queryParams.get("pageSize")
                    ? Number(queryParams.get("pageSize"))
                    : defaultFilter.pageSize,
                search: queryParams.get("search") || defaultFilter.search,
                sortBy: queryParams.get("sortBy") || defaultFilter.sortBy,
                sortOrder: (queryParams.get("sortOrder") as "asc" | "desc") || defaultFilter.sortOrder,
            };

            // Map any other dynamic params
            _.each(queryParams, (param, paramName) => {
                if (!(filter as any)[paramName]) {
                    (filter as any)[paramName] = Number.isNaN(param)
                        ? param
                        : Number(param);
                }
            });

            // Initialize overrides, if any
            _.each(defaultFilter, (param, paramName) => {
                if (!(filter as any)[paramName]) {
                    (filter as any)[paramName] = param;
                }
            });
            return filter;
        };

        return sanitizeNumericProps(filterFactory(qsParse()))
    }, [defaultFilter, filterFactory]);

    const loadData = useMemo(
        function () {
            return debounce(async (f: GridFilter) => {
                try {
                    setSharedIsLoading(true);
                    updateUrl(f);
                    const response = await refresh(f);
                    setGrid(response.data);
                    return response.data;
                } finally {
                    setSharedIsLoading(false);
                }
            }, 350);
        },
        [refresh, setSharedIsLoading, updateUrl]
    );

    useEffect(() => {
        const f = getFilter();
        window.location.search ? loadData(f) : updateUrl(f);
    }, [getFilter, loadData, updateUrl]);


    function sanitizeNumericProps(filter: GridFilter) {
        const newFilter = {};

        each(filter, (value, key) => {
            const parsed = toNumber(value);
            (newFilter as any)[key] = !!value && !isNaN(parsed) ? parsed : value;
        });

        return newFilter as GridFilter;
    }

    const getPagination = useCallback(() => {
        if (!grid) {
            const f = getFilter();
            return {
                current: f.page,
                pageSize: f.pageSize,
                pageCount: 0,
            } as GridPagination;
        }

        return {
            current: grid.currentPage,
            pageSize: grid.pageSize,
            pageCount: grid.pageCount,
        } as GridPagination;
    }, [getFilter, grid])

    return [grid, loadData, getPagination, getFilter] as const;
}