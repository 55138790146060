/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Paper,
  Box,
  CircularProgress,
  DialogContentText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Alert,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useOrganisationService } from "core/hooks";
import React, { useEffect, useState } from "react";
import { RoleDescriptionCpt } from "components/common";
import { GetLicenceLimitsModel } from "core/webapi";
import { useRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { AddUserTableCellCpt } from "./AddUserTableCellCpt";
import { AddUserTableRowCpt } from "./AddUserTableRowCpt";
interface AddUsersDialogFormCptProps {
  open: boolean;
  close: Function;
  organisationId: number;
  roleId: number;
}

export const AddUsersDialogFormCpt = (props: AddUsersDialogFormCptProps) => {
  const { open, close, organisationId, roleId } = props;
  const [isLoading, setSharedIsLoading] = useRecoilState(sharedLoadingState);
  const [internalOpen, setInternalOpen] = useState(open);
  const [model, setModel] = useState(new GetLicenceLimitsModel());
  const [users, setUsers] = useState([{ id: 1, saving: false }]);
  const { getLicenceLimits } = useOrganisationService();
  const [isSaving, setIsSaving] = useState(false);
  const [hasSavedAny, setHasSavedAny] = useState(false);

  const addNewUser = () => {
    const newId =
      !users || users.length === 0
        ? 1
        : users.reduce((prev, current) =>
            prev.id > current.id ? prev : current
          ).id + 1;

    setUsers((prevUsers) => [...prevUsers, { id: newId, saving: false }]);
  };

  const forceClose = (result: boolean) => {
    const shouldReload = result || hasSavedAny;
    setUsers([{ id: 1, saving: false }]);
    setInternalOpen(false);
    setHasSavedAny(false);
    close(shouldReload);
  };

  const handleClose = (result: boolean) => {
    if (isSaving) return;
    forceClose(result);
  };

  const save = () => {
    if (users.length > 0) {
      const newArray = [...users];
      newArray[0].saving = true;
      setUsers(newArray);
      setIsSaving(true);
    }
  };

  const onSaved = (success: boolean) => {
    if (!success) {
      const newArray = [...users];
      newArray[0].saving = false;
      setUsers(newArray);
      setIsSaving(false);
    } else {
      setHasSavedAny(true);
      setTimeout(() => {
        const newArray = [...users];
        newArray.splice(0, 1);
        if (newArray.length > 0) {
          newArray[0].saving = true;
          setUsers(newArray);
        } else {
          setIsSaving(() => false);
          forceClose(true);
        }
      }, 800);
    }
  };

  const onRemove = (index: number) => {
    if (!users[index].saving) {
      const newArray = [...users];
      newArray.splice(index, 1);
      setUsers(newArray);
    }
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  useEffect(() => {
    const loadModel = async () => {
      setSharedIsLoading(true);
      try {
        const resp = await getLicenceLimits({
          organisationId,
          roleId,
        });

        setModel(resp.data);
      } finally {
        setSharedIsLoading(false);
      }
    };
    loadModel();
  }, [getLicenceLimits, organisationId, roleId, setSharedIsLoading]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose(false)}
        PaperComponent={Paper}
        aria-label={`edit licence dialog`}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="add-user-dialog-title">
          Add new <RoleDescriptionCpt roleId={roleId} /> to your account
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            {model.current} ot {model.limit} Available
          </DialogContentText>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                marginTop: 2,
                border: 0,
              }}
            >
              <Table
                size="small"
                aria-label="users to add"
                sx={{
                  border: 0,
                }}
              >
                <TableHead>
                  <TableRow>
                    <AddUserTableCellCpt></AddUserTableCellCpt>
                    <AddUserTableCellCpt>First name</AddUserTableCellCpt>
                    <AddUserTableCellCpt>Last name</AddUserTableCellCpt>
                    <AddUserTableCellCpt>Email</AddUserTableCellCpt>
                    <AddUserTableCellCpt>ID (optional)</AddUserTableCellCpt>
                    <AddUserTableCellCpt></AddUserTableCellCpt>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!users || users.length <= 0 ? (
                    <TableRow>
                      <AddUserTableCellCpt colSpan={6}>
                        <Alert severity="info">
                          No users have been added yet
                        </Alert>
                      </AddUserTableCellCpt>
                    </TableRow>
                  ) : (
                    users.map((item, index) => (
                      <AddUserTableRowCpt
                        organisationId={organisationId}
                        roleId={roleId}
                        index={index}
                        key={item.id}
                        onSaved={onSaved}
                        save={item.saving}
                        onRemove={onRemove}
                        disableDelete={isSaving || users.length === 1}
                      />
                    ))
                  )}
                  <TableRow>
                    <AddUserTableCellCpt colSpan={6} align="right">
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          aria-controls="add-users"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="text"
                          size="small"
                          onClick={addNewUser}
                          startIcon={<AddIcon />}
                          sx={{ display: { xs: "none", sm: "flex" } }}
                          disabled={isSaving}
                        >
                          New Row
                        </Button>
                      </Box>
                    </AddUserTableCellCpt>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            variant="outlined"
            color="secondary"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={save}
            variant="contained"
            disabled={isSaving || users.length === 0}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
