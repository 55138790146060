/** @jsxImportSource @emotion/react */
import { Grid, Container, Typography } from "@mui/material";
import { TierCardCpt } from "components/public";
import { useEffect, useState } from "react";
import { useOrganisationService } from "core/hooks";
import { useSetRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { GetTierLookupsModel } from "core/webapi";
import { YellowTextCpt } from "components/common";
import * as colors from "styles/colors";

export const SelectTierView = () => {
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const [lookupModel, setLookupModel] = useState(
    () =>
      new GetTierLookupsModel({
        tiers: [],
      })
  );
  const { getTierLookups } = useOrganisationService();

  useEffect(() => {
    const loadTiers = async () => {
      setSharedIsLoading(true);
      try {
        const resp = await getTierLookups();
        setLookupModel(resp.data);
      } finally {
        setSharedIsLoading(false);
      }
    };
    loadTiers();
  }, [getTierLookups, setSharedIsLoading]);

  return (
    <Container fixed>
      <Typography
        variant="h1"
        component="h1"
        gutterBottom
        sx={{
          textAlign: "center",
        }}
      >
        <YellowTextCpt>Engage</YellowTextCpt>
        &nbsp;your students more than ever
      </Typography>
      <Typography
        variant="subtitle1"
        component="h2"
        sx={{
          color: colors.palette.palette.secondary.main,
          fontSize: 18,
          marginTop: 5,
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        As many seats as you need, for every type of classroom.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {lookupModel.tiers?.map((tier) => (
          <Grid key={tier.description} item>
            <TierCardCpt tier={tier}></TierCardCpt>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
