/** @jsxImportSource @emotion/react */
import { Typography, Box, Container } from "@mui/material";
import * as colors from "styles/colors";

export const CardTitleCpt = ({ children }: { children: any }) => {
  return (
    <Typography
      variant="h2"
      component="h2"
      sx={{
        fontFamily: "'Roboto', sans-serif;",
        color: colors.palette.palette.secondary.main,
        fontSize: "20px",
        width: "100%",
        paddingLeft: 0,
        paddingY: 1,
        fontWeight: 400,
      }}
    >
      {children}
    </Typography>
  );
};

export const CardSubTitleCpt = ({
  children,
  sx,
}: {
  children: any;
  sx?: any;
}) => {
  return (
    <Typography
      variant="h3"
      component="h3"
      sx={{
        fontFamily: "'Roboto', sans-serif;",
        color: colors.palette.palette.blue.main,
        fontSize: "14px",
        fontWeight: 400,
        marginBottom: 1.5,
        marginTop: 4,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CardBodyCpt = ({
  children,
  backgroundColor = colors.palette.palette.lightBackground.main,
  sx,
}: {
  children: any;
  backgroundColor?: string;
  sx?: any;
}) => {
  return (
    <Box
      component="div"
      sx={{
        fontFamily: "'Roboto', sans-serif;",
        fontSize: "16px",
        fontWeight: 400,
        pb: 1,
        px: 3,
        border: `1px solid ${backgroundColor}`,
        backgroundColor: backgroundColor,
        color: colors.palette.palette.secondary.main,
        borderRadius: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const CardCpt = ({ children, sx }: { children: any; sx?: any }) => {
  return (
    <Container
      component="div"
      maxWidth={false}
      sx={{
        p: 2,
        marginLeft: 0,
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};

export const CardTextCpt = ({ children }: { children: any }) => {
  return (
    <Typography
      variant="subtitle1"
      component="span"
      sx={{
        color: colors.palette.palette.secondary.main,
        fontSize: 15,
        marginBottom: 0.5,
      }}
    >
      {children}
    </Typography>
  );
};
