import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { SimpleLookup } from "core/webapi";
import { DtTextInputCpt } from "./DtTextInputCpt";
import { appFormComponent } from "core/providers";
export const DtSelectInputCpt = ({
  model,
  setModel,
  prop,
  label,
  items = [] as SimpleLookup[],
  valueProp = "id",
  textProp = "description",
  fullWidth = false,
  mandatory = false,
  multiple = false,
  disabled = false,
  onChange = (e: any) => {},
}: {
  model: any;
  setModel: Function;
  prop: string;
  label?: string;
  items?: any[] | SimpleLookup[] | null;
  valueProp?: string;
  textProp?: string;
  fullWidth?: boolean;
  mandatory?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: Function;
}) => {
  const { errorMessage, clearErrorMessage } = appFormComponent();
  const [error, setError] = useState(() => errorMessage(model, prop));

  useEffect(() => {
    setError(errorMessage(model, prop));
  }, [setError, errorMessage, model, prop]);

  if (!items || items.length === 0) {
    return (
      <DtTextInputCpt
        model={model}
        setModel={setModel}
        prop={prop}
        label={label}
        fullWidth={fullWidth}
        onChange={onChange}
      />
    );
  }

  const emptyItem = !mandatory ? <MenuItem value={0}>None</MenuItem> : null;
  const defaultValue = !mandatory ? 0 : "";

  const updateModel = (e: any) => {
    let internalModel = Object.assign({}, model);
    internalModel[prop] = e.target.value;
    clearErrorMessage(internalModel, prop);
    setModel(internalModel);
    onChange(e);
  };

  return (
    <FormControl
      variant="standard"
      size="small"
      fullWidth={fullWidth}
      error={!!error}
    >
      {!label ? null : (
        <InputLabel shrink htmlFor={prop}>
          {label}
        </InputLabel>
      )}
      <Select
        sx={{
          textAlign: "left",
          marginTop: "10px !important",
        }}
        disabled={disabled}
        defaultValue={defaultValue}
        id={prop}
        name={prop}
        value={model[prop] || ""}
        label={label}
        onChange={updateModel}
        multiple={multiple}
      >
        {emptyItem}
        {items?.map((item) => (
          <MenuItem value={item[valueProp]} key={item[valueProp]}>
            {item[textProp]}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
