import { atom } from "recoil";
import axios from "axios";
import { Configuration } from "core/webapi";
import { AccountApiFactory, OrganisationApiFactory, UserApiFactory } from "core/webapi";
import { NotificationProvider, AuthProvider } from "core/providers";

const getAxiosConfig = () => {
    return new Configuration({ basePath: process.env.REACT_APP_API_BASEPATH });
}

const getAxiosInstance = () => {
    const config = {
        headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        },
    };

    const axiosInstance = axios.create(config);

    axiosInstance.interceptors.request.use(
        async config => {
            if (AuthProvider.isAuthenticated() && config.headers) {
                const token = AuthProvider.getToken();
                config.headers.Authorization = `Bearer ${token}`;
                AuthProvider.refreshTokenAlmostExpiredIfNecessary();
            }
            return config;
        },
        error => error,
    );

    axiosInstance.interceptors.response.use(
        response => response,
        e => onError(e)
    );

    return axiosInstance
}

const onError = (e: any) => {
    if (e?.response?.status === 422) {
        // 422 is model validation error and we don't want any extra handling in that case
        return Promise.reject(e);
    }

    let message = 'An error occurred.';
    if (e?.response?.status) {
        switch (e.response.status) {
            case 401: {
                if (!AuthProvider.isAuthenticated()) {
                    message = 'Invalid session, please log in.';
                    AuthProvider.logout();
                } else {
                    message = 'Unauthorized action.';
                }
                break;
            }
            case 403: {
                message = 'Permission denied.';
                break;
            }
            case 500: {
                if (e?.response?.data?.message) {
                    message = e.response.data.message;
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    NotificationProvider.error(message);
    return Promise.reject(e);
};

export const accountServiceState = atom({
    key: "accountServiceState",
    default: AccountApiFactory(getAxiosConfig(), undefined, getAxiosInstance()),
});

export const organisationServiceState = atom({
    key: "organisationServiceState",
    default: OrganisationApiFactory(getAxiosConfig(), undefined, getAxiosInstance()),
});

export const userServiceState = atom({
    key: "userServiceState",
    default: UserApiFactory(getAxiosConfig(), undefined, getAxiosInstance()),
});