/** @jsxImportSource @emotion/react */
import {
  AddUserDropdownCpt,
  OrganisationAllocationsGridCpt,
} from "components/organisation";
import { Grid, Box, Container, Tab, Tabs } from "@mui/material";
import { Role } from "core/constants";
import { PageTitleCpt, TabPanelCpt } from "components/common";
import { useCallback, useEffect, useRef, useState } from "react";
import { GridFilter, useOrganisationService } from "core/hooks";
import {
  FilterOrganisationAllocationsModelGridCollection,
  FilterOrganisationAllocationsQuery,
} from "core/webapi";
import { eventHub } from "core/providers";
import { GridSearchInputCpt } from "components/grid";

export const OrganisationAllocationsCpt = ({
  tab,
  organisationId,
  onTabChanged,
  shouldUpdateUrl,
}: {
  tab: number;
  organisationId: number;
  onTabChanged: Function;
  shouldUpdateUrl: boolean;
}) => {
  const { filterOrganisationAllocations } = useOrganisationService();
  const isListenerAddedRef = useRef(false);
  const getRoleId = (internalTab: number) => {
    switch (internalTab) {
      case 0:
        return Role.OrganisationAdmin;
      case 1:
        return Role.Presenter;
      default:
        return Role.Participant;
    }
  };

  const [internalFilter, setInternalFilter] = useState(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return new GridFilter({
      sortBy: queryParams.get("sortBy") || "user.userDetail.firstName",
      sortOrder:
        (queryParams.get("sortOrder") as "" | "asc" | "desc" | undefined) ||
        "asc",
      search: queryParams.get("search") || "",
      roleId: getRoleId(tab),
      organisationId: organisationId,
    });
  });

  const [data, setData] = useState(
    new FilterOrganisationAllocationsModelGridCollection()
  );

  const refresh = useCallback(
    async (filter: FilterOrganisationAllocationsQuery) => {
      filter.organisationId = organisationId;
      const resp = await filterOrganisationAllocations(filter);
      setData(resp.data);
      return resp;
    },
    [filterOrganisationAllocations, organisationId]
  );

  const updateFilter = async (value: string) => {
    setInternalFilter((prevFilter) => ({
      ...prevFilter,
      search: value,
    }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChanged(newValue);
  };

  const tabProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const reload = useCallback(
    ({
      roleId,
      organisationId,
    }: {
      roleId: number;
      organisationId: number;
    }) => {
      setInternalFilter((prevFilter) => ({
        ...prevFilter,
        roleId,
        organisationId,
      }));
    },
    []
  );

  useEffect(() => {
    if (!isListenerAddedRef.current) {
      eventHub.on("ORGANISATION_UPDATE", reload);
      isListenerAddedRef.current = true;
    }

    return () => {
      eventHub.remove("ORGANISATION_UPDATE", reload);
    };
  }, [reload]);

  useEffect(() => {
    setInternalFilter((prevFilter) => ({
      ...prevFilter,
      roleId: getRoleId(tab),
    }));
  }, [tab]);

  return (
    <>
      <PageTitleCpt>
        <Grid
          justifyContent="space-between"
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={6}>
            Users
          </Grid>
          <Grid item xs={6} paddingRight={2}>
            <Grid container justifyContent="flex-end" spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent="flex-end">
                  <AddUserDropdownCpt
                    roleId={internalFilter.roleId}
                    organisationId={organisationId}
                  />
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <GridSearchInputCpt
                  defaultFilter={internalFilter}
                  onSearchChanged={updateFilter}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageTitleCpt>

      <Container component="div" maxWidth={false}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="user tabs">
              <Tab
                label="Administrators"
                {...tabProps(Role.OrganisationAdmin)}
              />
              <Tab label="Presenters" {...tabProps(Role.Presenter)} />
              <Tab label="Participants" {...tabProps(Role.Participant)} />
            </Tabs>
          </Box>
          <TabPanelCpt value={tab} index={0}>
            <OrganisationAllocationsGridCpt
              organisationId={organisationId}
              refresh={refresh}
              data={data}
              defaultFilter={internalFilter}
              shouldUpdateUrl={shouldUpdateUrl}
            />
          </TabPanelCpt>
          <TabPanelCpt value={tab} index={1}>
            <OrganisationAllocationsGridCpt
              organisationId={organisationId}
              refresh={refresh}
              data={data}
              defaultFilter={internalFilter}
              shouldUpdateUrl={shouldUpdateUrl}
            />
          </TabPanelCpt>
          <TabPanelCpt value={tab} index={2}>
            <OrganisationAllocationsGridCpt
              organisationId={organisationId}
              refresh={refresh}
              data={data}
              defaultFilter={internalFilter}
              shouldUpdateUrl={shouldUpdateUrl}
            />
          </TabPanelCpt>
        </Box>
      </Container>
    </>
  );
};
