import {
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Box,
} from "@mui/material";
import { GetTierLookupsTierLookup } from "core/webapi";
import * as colors from "styles/colors";
import { useParams } from "react-router-dom";

export const TierCardTypographyCpt = ({ text }: { text: string }) => {
  return (
    <Typography
      variant="h6"
      sx={{
        color: colors.palette.palette.blue.main,
      }}
    >
      {text}
    </Typography>
  );
};

export const TierCardCpt = ({ tier }: { tier: GetTierLookupsTierLookup }) => {
  const params = useParams();

  const title = (
    <Box
      sx={{
        backgroundColor: colors.palette.palette.primary.main,
        color: colors.palette.palette.primary.contrastText,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        fontSize: 15,
        paddingTop: 0.3,
        paddingBottom: 0.4,
      }}
    >
      {tier.description}
    </Box>
  );
  return (
    <Card
      sx={{
        width: 245,
        height: 250,
        border:
          params.tierId && Number(params.tierId) === tier.id
            ? `3px inset ${colors.palette.palette.primary.main}`
            : "0",
      }}
    >
      <CardHeader
        title={title}
        sx={{
          textAlign: "center",
          paddingTop: 0,
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 3,
        }}
      ></CardHeader>
      <CardContent
        sx={{
          textAlign: "center",
          height: 150,
        }}
      >
        {tier.presenterLimit && tier.presenterLimit > 0 ? (
          <>
            <TierCardTypographyCpt
              text={`${tier.presenterLimit} Presenters`}
            ></TierCardTypographyCpt>
            <TierCardTypographyCpt
              text={`${tier.participantLimit} Participants`}
            ></TierCardTypographyCpt>
          </>
        ) : (
          <TierCardTypographyCpt text="Institutes"></TierCardTypographyCpt>
        )}
      </CardContent>
      <CardActions
        sx={{
          textAlign: "center",
          display: "block",
        }}
      >
        {tier.presenterLimit && tier.presenterLimit > 0 ? (
          <Button size="small" variant="outlined" href={`/register/${tier.id}`}>
            Get started
          </Button>
        ) : (
          <Button size="small" variant="outlined">
            Contact us
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
