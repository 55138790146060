/** @jsxImportSource @emotion/react */
import { Container, Toolbar, Typography } from "@mui/material";
import { ReactComponent as Logo } from "styles/logo_unidoodle_white.svg";
import { useState } from "react";
import * as colors from "styles/colors";
import { AppBarCpt } from "./AppBarCpt";

export const UnauthenticatedAppBarCpt = () => {
  const [environment] = useState(process.env.REACT_APP_ENVIRONMENT);

  return (
    <AppBarCpt
      open={false}
      sx={{
        position: "sticky",
      }}
    >
      <Container maxWidth={false}>
        <Toolbar
          sx={{
            position: "relative",
            padding: "0 !important",
          }}
        >
          {environment === "Production" ? null : (
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{
                color: colors.palette.palette.lightGray.main,
              }}
            >
              Test app
            </Typography>
          )}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://www.unidoodle.com/"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              position: "relative",
              display: "block",
            }}
          >
            <Logo
              css={{
                maxWidth: "160px",
                marginTop: 10,
              }}
            />
          </Typography>
        </Toolbar>
      </Container>
    </AppBarCpt>
  );
};
