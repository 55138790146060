export const AuthProvider = {
    auth: null as any,
    isAuthenticated: () => (AuthProvider.auth)
        ? AuthProvider.auth.isAuthenticated()
        : console.error("invalid auth"),
    logout: () => (AuthProvider.auth)
        ? AuthProvider.auth.logout()
        : console.error("invalid auth"),
    getToken: () => (AuthProvider.auth)
        ? AuthProvider.auth.getToken()
        : console.error("invalid auth"),
    refreshTokenAlmostExpiredIfNecessary: () => (AuthProvider.auth)
        ? AuthProvider.auth.refreshTokenAlmostExpiredIfNecessary()
        : console.error("invalid auth"),
    getPayload: () => (AuthProvider.auth)
        ? AuthProvider.auth.getPayload()
        : console.error("invalid auth"),
}