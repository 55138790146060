export const palette = {
  palette: {
    primary: {
      main: "#5C8BD2",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#474A57",
      contrastText: "#FFF",
    },
    info: {
      main: "#B0D5EF",
      contrastText: "#474A57",
    },
    error: {
      main: "#E3614C",
      contrastText: "#FFF",
    },
    warning: {
      main: "#FEB659",
      contrastText: "#474A57",
    },
    success: {
      main: "#50C9B5",
      contrastText: "#FFF",
    },
    white: {
      main: "#FFF",
      contrastText: "#474A57",
    },
    blue: {
      main: "#3E598E",
      contrastText: "#FFF",
    },
    yellow: {
      main: "#FEAE47",
      contrastText: "#5461346",
    },
    lightGray: {
      main: "#E2E6EE",
      contrastText: "#5461346",
    },
    bronze: {
      main: "#71530E",
      contrastText: "#FFF",
    },
    input: {
      main: "#F1F7FD",
      contrastText: "#474A57",
    },
    inputDisabled: {
      main: "#F1F7FD",
      contrastText: "#B0ADBF",
    },
    tableHeader: {
      main: "#DDE1EB",
      contrastText: "#3E598E",
    },
    black: {
      main: "#000",
      contrastText: "#fff",
    },
    lightBackground: {
      main: "#F4F6F9",
      contrastText: "#3E598E",
    },
    paginationBackground: {
      main: "#D9D9D9",
      contrastText: "#000",
    },
  },
};
