/** @jsxImportSource @emotion/react */
import { Container } from "@mui/material";
export const PageContainerCpt = ({ children }: { children: any }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
        marginLeft: "0 !important",
        marginRight: "0 !important",
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      }}
    >
      {children}
    </Container>
  );
};
