/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";

interface ConfirmDialogProps {
  title: string;
  description: any;
  open: boolean;
  close: Function;
}

export const ConfirmDialogCpt = (props: ConfirmDialogProps) => {
  const { title, description, open, close } = props;
  const [internalOpen, setInternalOpen] = useState(open);

  const handleClose = (result: boolean) => {
    setInternalOpen(false);
    close(result);
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose(false)}
        PaperComponent={Paper}
        aria-label={`${title} dialog`}
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => handleClose(false)}
            variant="outlined"
            color="secondary"
          >
            No
          </Button>
          <Button
            size="small"
            onClick={() => handleClose(true)}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
