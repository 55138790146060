import { useAuth, usePermission } from "core/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import {
//   OrganisationAdminDashboardCpt,
//   SuperUserDashboardCpt,
// } from "components/dashboard";

export const DashboardView = () => {
  const { getPayload } = useAuth();
  const navigate = useNavigate();
  const { isOrganisationAdmin, isSuperUser } = usePermission();

  useEffect(() => {
    // We are only redirecting because the dashboard will be implemented in the future
    if (isSuperUser()) {
      navigate("/admin/organisations");
      // return <SuperUserDashboardCpt></SuperUserDashboardCpt>;
    } else if (isOrganisationAdmin()) {
      const payload = getPayload();
      navigate(`/admin/licence/${payload.organisationId}/0`);
      // return <OrganisationAdminDashboardCpt></OrganisationAdminDashboardCpt>;
    } else {
      navigate("/not-found");
    }
  }, [getPayload, isOrganisationAdmin, isSuperUser, navigate]);

  return null;
};
