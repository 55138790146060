/** @jsxImportSource @emotion/react */
import { Typography, Grid } from "@mui/material";
import { useState } from "react";
import { SendResetPasswordEmailCommand } from "core/webapi";
import { useAccountService } from "core/hooks";
import { DtTextInputCpt, FormCpt } from "components/form";
import * as colors from "styles/colors";
import { AccountBoxContainerCpt } from "components/account";

export const RequestResetPasswordFormView = () => {
  const { sendResetPasswordEmail } = useAccountService();
  const [model, setModel] = useState(() => new SendResetPasswordEmailCommand());
  const [hasRequestedPasswordReset, setHasRequestedPasswordReset] =
    useState(false);

  const handleSubmit = async (event: any) => {
    setModel(new SendResetPasswordEmailCommand());
    setHasRequestedPasswordReset(true);
  };

  return (
    <AccountBoxContainerCpt subTitle={"Reset password"}>
      <FormCpt
        model={model}
        setModel={setModel}
        submitButtonText="Send reset link"
        submit={sendResetPasswordEmail}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          direction="row"
          justifyContent="top"
          alignItems="stretch"
          sx={{
            maxWidth: "500px",
            display: "inline-block",
          }}
        >
          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="email"
              label="Email"
              fullWidth
              type="email"
            />
          </Grid>
          {hasRequestedPasswordReset ? (
            <Grid xs={12} item>
              <Typography
                variant="subtitle1"
                component="span"
                sx={{
                  color: colors.palette.palette.primary.main,
                  fontSize: 18,
                  marginTop: 1,
                  marginBottom: 3,
                  textAlign: "center",
                }}
              >
                Email sent!
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </FormCpt>
    </AccountBoxContainerCpt>
  );
};
