/** @jsxImportSource @emotion/react */
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import * as colors from "styles/colors";

interface RegisteringContainerProps {
  children: any;
  title: any;
  subTitle: any;
  sideBox: any;
}

export const RegisteringContainerCpt = (props: RegisteringContainerProps) => {
  const { children, title, subTitle, sideBox } = props;
  return (
    <Container
      maxWidth={false}
      sx={{
        paddingX: "0px !important",
      }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        direction="row"
        justifyContent="top"
        alignItems="stretch"
      >
        <Grid
          xs={12}
          md={6}
          item
          sx={{
            paddingX: "280px !important",
            textAlign: "center",
          }}
        >
          {!title ? null : (
            <Typography
              variant="h1"
              component="h1"
              sx={{
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              {title}
            </Typography>
          )}
          {!subTitle ? null : (
            <Typography
              variant="subtitle1"
              component="h2"
              sx={{
                color: colors.palette.palette.secondary.main,
                fontSize: 18,
                marginTop: 4,
                marginBottom: 3,
                textAlign: "start",
              }}
            >
              {subTitle}
            </Typography>
          )}
          {children}
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          sx={{
            backgroundColor: colors.palette.palette.lightBackground.main,
            position: "absolute",
            left: "50%",
            right: 0,
            height: "calc(100vh - 65px)",
          }}
        >
          {sideBox}
        </Grid>
      </Grid>
    </Container>
  );
};
