import { Box, CssBaseline, Container, Grid, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AuthenticatedAppBarCpt } from "components/common";
// import { FooterCpt } from "components/footer";
import { DrawerCpt } from "components/common";
export const AuthenticatedLayout = () => {
  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <AuthenticatedAppBarCpt />
      <DrawerCpt />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
          width: "100%",
        }}
      >
        <Toolbar />
        <Container
          maxWidth={false}
          sx={{
            paddingX: "1px !important",
            maxWidth: "inherit !important",
            width: "100%",
            marginLeft: "0 !important",
            marginRight: "0 !important",
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
        {/* <FooterCpt></FooterCpt> */}
      </Box>
    </Box>
  );
};
