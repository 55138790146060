/** @jsxImportSource @emotion/react */
import { Grid } from "@mui/material";
import { useState } from "react";
import { ResetPasswordCommand } from "core/webapi";
import { useAccountService } from "core/hooks";
import { DtPasswordTextInputCpt, FormCpt } from "components/form";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useNotificationProvider } from "core/hooks";
import { AccountBoxContainerCpt } from "components/account";

export const ResetPasswordFormView = () => {
  const { success } = useNotificationProvider();
  const params = useParams();
  const navigate = useNavigate();

  const { resetPassword } = useAccountService();
  const [model, setModel] = useState(() => new ResetPasswordCommand());

  model.userId = Number(params.userId);
  model.token = params.token || "";

  const handleSubmit = async (event: any) => {
    success("Password successfully reset");
    navigate("/account/login");
  };

  return (
    <AccountBoxContainerCpt subTitle={"Set new password"}>
      <FormCpt
        model={model}
        setModel={setModel}
        submitButtonText="Submit"
        submit={resetPassword}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          direction="row"
          justifyContent="top"
          alignItems="stretch"
          sx={{
            maxWidth: "500px",
            display: "inline-block",
          }}
        >
          <Grid xs={12} item>
            <DtPasswordTextInputCpt
              model={model}
              setModel={setModel}
              prop="password"
              label="Password"
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <DtPasswordTextInputCpt
              model={model}
              setModel={setModel}
              prop="confirmPassword"
              label="Confirm Password"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormCpt>
    </AccountBoxContainerCpt>
  );
};
