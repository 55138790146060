import { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";
import { appFormComponent } from "core/providers";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";

export const DtPasswordTextInputCpt = ({
  model,
  setModel,
  prop,
  label,
  fullWidth = false,
  disabled = false,
  onChange = (e: any) => {},
}: {
  model: any;
  setModel: Function;
  prop: string;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: Function;
}) => {
  const { errorMessage, clearErrorMessage } = appFormComponent();
  const [error, setError] = useState(() => errorMessage(model, prop));
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setError(errorMessage(model, prop));
  }, [setError, errorMessage, model, prop]);

  const updateModel = (e: any) => {
    let internalModel = Object.assign({}, model);
    internalModel[prop] = e.target.value;
    clearErrorMessage(internalModel, prop);
    setModel(internalModel);
    onChange(e);
  };

  return (
    <FormControl variant="standard" error={!!error} fullWidth={fullWidth}>
      {!label ? null : (
        <InputLabel shrink htmlFor={prop}>
          {label}
        </InputLabel>
      )}
      <Input
        disabled={disabled}
        error={!!error}
        type={showPassword ? "text" : "password"}
        fullWidth={fullWidth}
        id={prop}
        name={prop}
        onChange={updateModel}
        value={model[prop] || ""}
        size="small"
        autoComplete="off"
        endAdornment={
          <IconButton
            size="small"
            color="primary"
            aria-label="show/hide password"
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
              event.preventDefault()
            }
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        }
      />
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
