import { Alert, AlertTitle, Container } from "@mui/material";

export const ErrorMessageView = ({ error, ...props }: any) => (
  <Container
    sx={{
      textAlign: "center",
    }}
  >
    <Alert severity="warning">
      <AlertTitle>There was an error:</AlertTitle>
      {error.message}
    </Alert>
  </Container>
);
