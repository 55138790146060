import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AppLayout } from "layouts/AppLayout";
import { SelectTierView } from "pages/Public";
import { ErrorMessageView } from "pages/Error";
import {
  RegisterFormView,
  LoginFormView,
  ConfirmationEmailSentView,
  EmailConfirmedView,
  RequestResetPasswordFormView,
  ResetPasswordFormView,
  CompleteRegistrationFormView,
  RegistrationCompletedView,
} from "pages/Account";

import { DashboardView } from "pages/Home";
import { useAuth } from "core/hooks";
import {
  LicenceManagementView,
  OrganisationAllocationsGridView,
  OrganisationGridView,
} from "pages/Organisation";

import { UserGridView } from "pages/User";
import { usePermission } from "core/hooks";

const PublicAppRoute = ({ children }: { children: any }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return <Navigate replace to="/" />;
  }
  //Not signed in
  return children;
};

const RegistrationNotCompletedRoute = ({ children }: { children: any }) => {
  const { isAuthenticated, hasCompletedRegistration } = useAuth();

  if (!isAuthenticated()) {
    //Not signed in
    return <Navigate replace to="/account/login" />;
  }

  if (hasCompletedRegistration()) {
    return <Navigate replace to="/" />;
  }
  //Signed in
  return children;
};

const AppRoute = ({ children }: { children: any }) => {
  const { isAuthenticated, hasCompletedRegistration } = useAuth();

  if (!isAuthenticated()) {
    //Not signed in
    return <Navigate replace to="/account/login" />;
  }

  if (!hasCompletedRegistration()) {
    return <Navigate replace to="/register/complete" />;
  }
  //Signed in
  return children;
};

const SuperUserRoute = ({ children }: { children: any }) => {
  const { isSuperUser } = usePermission();

  if (!isSuperUser()) {
    //Not signed in
    return <Navigate replace to="/not-found" />;
  }

  return AppRoute({ children });
};

export const NavigationRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route
            index
            element={
              <AppRoute>
                <DashboardView />
              </AppRoute>
            }
          />
          <Route path="/register">
            <Route path="tiers">
              <Route
                index
                element={
                  <PublicAppRoute>
                    <SelectTierView />
                  </PublicAppRoute>
                }
              />
              <Route
                path=":tierId"
                element={
                  <PublicAppRoute>
                    <SelectTierView />
                  </PublicAppRoute>
                }
              />
            </Route>
            <Route
              path=":tierId"
              element={
                <PublicAppRoute>
                  <RegisterFormView />
                </PublicAppRoute>
              }
            />
            <Route
              path="email/confirmed/:userId/:token"
              element={<EmailConfirmedView />}
            />
            <Route
              path="complete"
              element={
                <RegistrationNotCompletedRoute>
                  <CompleteRegistrationFormView />
                </RegistrationNotCompletedRoute>
              }
            />
            <Route
              path="completed"
              element={
                <AppRoute>
                  <RegistrationCompletedView />
                </AppRoute>
              }
            />
            <Route
              path="confirmation/sent/:userId"
              element={<ConfirmationEmailSentView />}
            />
          </Route>
          <Route path="/account">
            <Route
              path="login"
              element={
                <PublicAppRoute>
                  <LoginFormView />
                </PublicAppRoute>
              }
            />
            <Route
              path="request/reset/password"
              element={<RequestResetPasswordFormView />}
            />
            <Route
              path="reset/password/:userId/:token"
              element={<ResetPasswordFormView />}
            />
          </Route>
          <Route path="/admin">
            <Route
              path="organisation/users/:tab"
              element={
                <AppRoute>
                  <OrganisationAllocationsGridView />
                </AppRoute>
              }
            />
            <Route
              path="licence/:organisationId/:tab"
              element={
                <AppRoute>
                  <LicenceManagementView />
                </AppRoute>
              }
            />
            <Route
              path="organisations"
              element={
                <SuperUserRoute>
                  <OrganisationGridView />
                </SuperUserRoute>
              }
            />
            <Route
              path="users"
              element={
                <SuperUserRoute>
                  <UserGridView />
                </SuperUserRoute>
              }
            />
          </Route>
          <Route
            path="not-found"
            element={
              <ErrorMessageView error={{ message: "404: Page not found" }} />
            }
          />

          <Route
            path="*"
            element={
              <ErrorMessageView error={{ message: "404: Page not found" }} />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
