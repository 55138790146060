/** @jsxImportSource @emotion/react */
import {
  IconButton,
  Toolbar,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilState } from "recoil";
import { openDrawerState } from "core/atoms";
import { AuthenticatedUserDropdownCpt } from "./AuthenticatedUserDropdownCpt";
import * as colors from "styles/colors";
import { useState } from "react";
import { AppBarCpt } from "./AppBarCpt";

export const AuthenticatedAppBarCpt = () => {
  const [environment] = useState(process.env.REACT_APP_ENVIRONMENT);
  const [open, setOpen] = useRecoilState(openDrawerState);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <AppBarCpt
      open={open}
      sx={{
        backgroundColor: colors.palette.palette.lightGray.main,
        color: colors.palette.palette.lightGray.contrastText,
      }}
    >
      <Container maxWidth={false}>
        <Toolbar
          sx={{
            position: "relative",
            padding: "0 !important",
          }}
        >
          <Grid
            justifyContent="space-between"
            container
            sx={{
              marginLeft: open ? 0.5 : 0,
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                paddingLeft: "0 !important",
              }}
            >
              <Grid justifyContent="start" container>
                <Grid item>
                  <IconButton
                    edge="start"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                      color: colors.palette.palette.blue.main,
                      marginRight: "16px",
                      ...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                {environment === "Production" ? null : (
                  <Grid
                    item
                    sx={{
                      paddingLeft: "0 !important",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      noWrap
                      component="span"
                      sx={{
                        color: colors.palette.palette.lightGray.contrastText,
                      }}
                    >
                      Test app
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                paddingLeft: "0 !important",
              }}
            >
              <Grid justifyContent="end" container>
                <Grid item>
                  <AuthenticatedUserDropdownCpt />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBarCpt>
  );
};
