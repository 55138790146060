/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { useAccountService } from "core/hooks";
import { useEffect, useState } from "react";
import { EditUserCommand } from "core/webapi";
import { useSetRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { DtTextInputCpt, FormCpt } from "components/form";

interface EditUsersDialogFormCptProps {
  open: boolean;
  close: Function;
  organisationId?: number;
  userId: number;
}

export const EditUsersDialogFormCpt = (props: EditUsersDialogFormCptProps) => {
  const { open, close, organisationId, userId } = props;
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const [internalOpen, setInternalOpen] = useState(open);
  const { editUser, getEditUser } = useAccountService();
  const [model, setModel] = useState(
    new EditUserCommand({ organisationId: organisationId || 0, userId: userId })
  );

  useEffect(() => {
    const loadModel = async () => {
      if(!userId) return;
      setSharedIsLoading(true);
      try {
        const resp = await getEditUser(userId, organisationId ?? 0);
        setModel({
          ...resp.data,
          organisationId: organisationId ?? 0,
          userId: userId,
        });
      } finally {
        setSharedIsLoading(false);
      }
    };
    loadModel();
  }, [getEditUser, organisationId, setSharedIsLoading, userId]);

  const handleClose = (result: boolean) => {
    setInternalOpen(false);
    close(result);
  };

  const save = async () => {
    setSharedIsLoading(true);
    try {
      await editUser(model);
    } finally {
      setSharedIsLoading(false);
    }
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose(false)}
        PaperComponent={Paper}
        aria-label={`edit licence dialog`}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="add-user-dialog-title">Edit user</DialogTitle>
        <DialogContent>
          <FormCpt
            onSubmit={() => handleClose(true)}
            model={model}
            setModel={setModel}
            submitButtonText="Save"
            submit={save}
            fullWithButton={false}
            actions={
              <Button
                onClick={() => handleClose(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            }
          >
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="top"
              alignItems="stretch"
            >
              <Grid xs={12} item>
                <DtTextInputCpt
                  model={model}
                  setModel={setModel}
                  prop="email"
                  label="Email"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid xs={12} item>
                <DtTextInputCpt
                  model={model}
                  setModel={setModel}
                  prop="firstname"
                  label="First name"
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <DtTextInputCpt
                  model={model}
                  setModel={setModel}
                  prop="lastname"
                  label="Lastname"
                  fullWidth
                />
              </Grid>
              {!organisationId ? null : (
                <Grid xs={12} item>
                  <DtTextInputCpt
                    model={model}
                    setModel={setModel}
                    prop="organisationSpecificIdentifier"
                    label="Identifier (optional)"
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </FormCpt>
        </DialogContent>
      </Dialog>
    </>
  );
};
