import { useSetRecoilState } from 'recoil';
import { useAccountService, useNotificationProvider } from "core/hooks";
import { useNavigate } from "react-router-dom";
import { LoginCommand } from "core/webapi";
import { sharedLoadingState } from "core/atoms";
import * as moment from "moment";
import { config } from "core/constants";
import { useRef } from 'react';

const localStorageTokenKey = "unidoodle_token";

export const getToken = () => {
    const lsToken = localStorage.getItem(localStorageTokenKey);
    if (lsToken) {
        const token = JSON.parse(lsToken);
        return token.accessToken;
    }

    return null;
};

export const isAuthenticated = () => {
    if (!getToken()) {
        return false;
    }

    // expired token
    return getRemainingTokenMinutes() > 0;
};

export const decodeJwt = (token: string) => {
    const jsonPayload = window.Buffer.from(token.split('.')[1], 'base64').toString();
    return JSON.parse(jsonPayload);
};

export const hasCompletedRegistration = () => {
    const payload = getPayload();
    if (!payload) return false;
    return payload.hasCompletedRegistration.toLowerCase() === "true";
}

export const getPayload = () => {
    if (!isAuthenticated()) {
        return null;
    }

    return getTokenPayload(getToken());
}

export const getTokenPayload = (token: string) => {
    if (!token) {
        return null;
    }

    return decodeJwt(token);
}

const getRemainingTokenDuration = () => {
    const token = getToken();
    if (!token) {
        return moment.duration(0);
    }

    const payload = getTokenPayload(token);

    if (!payload) {
        return moment.duration(0);
    }

    const expiryTime = moment.unix(payload.exp).utc();
    const now = moment.utc();
    const diff = moment.duration(expiryTime.diff(now));

    return diff;
}

const getRemainingTokenMinutes = () => {
    return getRemainingTokenDuration().asMinutes();
}

export const useAuth = () => {
    const isTokenRefreshingInProcess = useRef(false);
    const navigate = useNavigate();
    const { login, refreshToken } = useAccountService();
    const { warning } = useNotificationProvider();
    const setSharedIsLoading = useSetRecoilState(sharedLoadingState);

    const slowNavigate = (to: any, options?: any) => {
        setSharedIsLoading(false);
        setTimeout(() => {
            navigate(to, options);
        }, 500);
    };

    return {
        authenticate,
        logout,
        getPayload,
        refreshTokenAlmostExpiredIfNecessary,
        getToken,
        setToken,
        isAuthenticated,
        refresh,
        hasCompletedRegistration
    };

    function setToken(token: any) {
        if (token) {
            localStorage.setItem(localStorageTokenKey, JSON.stringify(token));
        } else {
            // if setting a empty/null token and there is a token, it should remove it
            const lsToken = localStorage.getItem(localStorageTokenKey);
            if (lsToken) {
                localStorage.removeItem(localStorageTokenKey);
            }
        }
    };

    async function refresh() {
        if (!isAuthenticated()) {
            isTokenRefreshingInProcess.current = false;
            return;
        }
        try {
            const resp = await refreshToken();
            setToken(resp.data);
            isTokenRefreshingInProcess.current = false;
        } catch (err) {
            console.error(err);
            logout();
        }
    }

    async function authenticate(model: LoginCommand) {
        setSharedIsLoading(true);
        try {
            const resp = await login(model);
            const data = resp.data;
            if (!data.emailConfirmed) {
                setToken(null);
                warning("Please confirm your email address");
                slowNavigate(`/register/confirmation/sent/${data.userId}`);
                return data;
            }

            setToken(data.token);
            if (data.hasCompletedRegistration) {
                slowNavigate('/');
            } else {
                slowNavigate('/register/complete');
            }
            return resp;
        } finally {
            setSharedIsLoading(false);
        }
    }

    function logout() {
        setToken(null);
        navigate('/account/login');
    }

    function refreshTokenAlmostExpiredIfNecessary() {
        const diff = getRemainingTokenMinutes();
        if (!isTokenRefreshingInProcess.current
            && diff < config.auth.refreshTokenThreshold && diff > 0) {
            isTokenRefreshingInProcess.current = true;
            refresh();
        }
    }
}