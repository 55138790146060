import { PageContainerCpt, PageTitleCpt } from "components/common";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useState } from "react";
import {
  FilterUserModelGridCollection,
  FilterUserModel,
  FilterOrganisationQuery,
} from "core/webapi";
import {
  GridFilter,
  useAccountService,
  useNotificationProvider,
  useUserService,
} from "core/hooks";
import { GridCpt, GridSearchInputCpt } from "components/grid";
import {
  Alert,
  Button,
  Container,
  Grid,
  Link,
  TableCell,
  TableRow,
} from "@mui/material";
import { ConfirmDialogCpt } from "components/common";
import { useSetRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { CreateSuperUserDialogFormCpt } from "components/user";
import { EditUsersDialogFormCpt } from "components/account";
import { Role } from "core/constants";

export const UserGridView = () => {
  const { success } = useNotificationProvider();
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const { filterUsers, disableUser } = useUserService();
  const { resendInvite } = useAccountService();
  const [data, setData] = useState({} as FilterUserModelGridCollection);
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(new FilterUserModel());
  const [editUserDialog, setEditUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);

  const getDefaultFilter = () =>
    new GridFilter({
      sortBy: "userDetail.firstName",
      sortOrder: "asc",
      search: "",
    });

  const [defaultFilter, setDefaultFilter] = useState(() => getDefaultFilter());

  const refresh = useCallback(
    async (filter: FilterOrganisationQuery) => {
      const resp = await filterUsers(filter);
      setData(resp.data);
      return resp;
    },
    [filterUsers]
  );

  const onCloseCreateUserDialog = async (result: boolean) => {
    setOpenCreateUserDialog(false);
    if (result) {
      // Force refresh
      setDefaultFilter((prevFilter) => ({
        ...prevFilter,
      }));
    }
  };

  const handleUpdateFilter = async (value: string) => {
    setDefaultFilter((prevFilter) => ({
      ...prevFilter,
      search: value,
    }));
  };

  const handleResendInvite = async (user: FilterUserModel) => {
    setSharedIsLoading(true);
    try {
      await resendInvite({
        userId: user.id,
        organisationId: 0,
        roleId: Role.SuperUser,
      });
      success("Invite successfully sent");
    } finally {
      setSharedIsLoading(false);
    }
  };

  const handleEditUser = (user: FilterUserModel) => {
    setCurrentUser(user);
    setEditUserDialog(true);
  };

  const closeEditUserDialog = async (answer: boolean) => {
    setEditUserDialog(false);
    if (answer) {
      setDefaultFilter((prevFilter) => ({
        ...defaultFilter,
        ...prevFilter,
      }));
    }
  };

  const handleDeleteUser = (user: FilterUserModel) => {
    setCurrentUser(user);
    setDeleteUserDialog(true);
  };

  const closeDeleteUserDialog = async (answer: boolean) => {
    setDeleteUserDialog(false);
    if (answer) {
      setSharedIsLoading(true);
      try {
        await disableUser({
          userId: currentUser.id,
        });
        success("User successfully deleted");
        // force grid refresh
        setDefaultFilter((prevFilter) => ({
          ...defaultFilter,
          ...prevFilter,
        }));
      } finally {
        setSharedIsLoading(false);
        setCurrentUser(new FilterUserModel());
      }
    }
  };

  const headers = [
    { title: "Name", sortBy: "userDetail.firstName" },
    { title: "Email", sortBy: "email" },
    { title: "Status" },
    { title: "Role" },
    { title: "", align: "right" },
  ];

  return (
    <PageContainerCpt>
      <PageTitleCpt>
        <Grid
          justifyContent="space-between"
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={6}>
            UniDoodle Admin Users
          </Grid>
          <Grid item xs={6} paddingRight={2}>
            <Grid container justifyContent="flex-end" spacing={4}>
              <Grid item xs={7}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenCreateUserDialog(true)}
                  >
                    Admin
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <GridSearchInputCpt
                  defaultFilter={defaultFilter}
                  onSearchChanged={handleUpdateFilter}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageTitleCpt>
      <Container component="div" maxWidth={false} sx={{ marginTop: 2 }}>
        <GridCpt
          refresh={refresh}
          defaultFilter={defaultFilter}
          headers={headers}
        >
          {data.items && data.items.length > 0 ? (
            data.items?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.fullName}</TableCell>
                <TableCell>
                  <Link href={`mailto:${item.email}`} underline="always">
                    {item.email}
                  </Link>
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.role}</TableCell>
                <TableCell align="right" width="300">
                  {item.canGetAnInvite ? (
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={() => handleResendInvite(item)}
                      sx={{
                        fontFamily: "Roboto",
                      }}
                    >
                      Resend invite
                    </Button>
                  ) : null}
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={() => handleEditUser(item)}
                    sx={{
                      fontFamily: "Roboto",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    disabled={item.isDeleted}
                    onClick={() => handleDeleteUser(item)}
                    sx={{
                      fontFamily: "Roboto",
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Alert severity="info">No users have been registered yet</Alert>
              </TableCell>
            </TableRow>
          )}
        </GridCpt>
      </Container>
      <CreateSuperUserDialogFormCpt
        open={openCreateUserDialog}
        close={onCloseCreateUserDialog}
      />
      <EditUsersDialogFormCpt
        open={editUserDialog}
        close={closeEditUserDialog}
        userId={currentUser.id}
      />
      <ConfirmDialogCpt
        title="Are you sure?"
        description="Are you sure you want to delete this user?"
        open={deleteUserDialog}
        close={closeDeleteUserDialog}
      />
    </PageContainerCpt>
  );
};
