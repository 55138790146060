import { useState } from "react";
import { Button, Divider, IconButton, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useAuth } from "core/hooks";
import { StyledMenuCpt } from "./StyledMenuCpt";

export const InternalMenuItemCpt = ({
  children,
  clickable = false,
  onClick = () => {},
}: {
  children: any;
  clickable?: boolean;
  onClick?: Function;
}) => {
  const handleClick = () => {
    if (clickable && onClick) {
      onClick();
    }
  };

  return (
    <MenuItem
      onClick={handleClick}
      disableRipple
      sx={{
        cursor: clickable ? "pointer" : "default",
      }}
    >
      {children}
    </MenuItem>
  );
};

export const AuthenticatedUserDropdownCpt = () => {
  const auth = useAuth();
  const [payload] = useState(() => auth.getPayload());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    handleClose();
    auth.logout();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="logout-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        {payload.firstName} {payload.lastName}
      </Button>
      <IconButton
        color="primary"
        aria-controls="logout-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        size="small"
        onClick={handleClick}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <PersonOutlineIcon />
      </IconButton>
      <StyledMenuCpt
        id="logout-menu"
        MenuListProps={{
          "aria-labelledby": "logout menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <InternalMenuItemCpt>{payload.userEmail}</InternalMenuItemCpt>
        <Divider />
        {!payload.organisationName ? null : !Array.isArray(
            payload.organisationName
          ) ? (
          <div>
            <InternalMenuItemCpt>
              {payload.organisationName}
            </InternalMenuItemCpt>
            <Divider />
          </div>
        ) : (
          <div>
            {payload.organisationName.map((org: string) => (
              <InternalMenuItemCpt key={org}>{org}</InternalMenuItemCpt>
            ))}
            <Divider />
          </div>
        )}
        <InternalMenuItemCpt onClick={handleLogout} clickable>
          Sign out
        </InternalMenuItemCpt>
      </StyledMenuCpt>
    </>
  );
};
