/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { DtDateTimePickerInputCpt, FormCpt } from "components/form";
import { useOrganisationService } from "core/hooks";
import { UpdateLicenceDatesCommand } from "core/webapi";
import { useEffect, useState } from "react";

interface UpdateLicenceDatesDialogFormProps {
  open: boolean;
  close: Function;
  organisation: UpdateLicenceDatesCommand;
}

export const UpdateLicenceDatesDialogFormCpt = (
  props: UpdateLicenceDatesDialogFormProps
) => {
  const { open, close, organisation } = props;
  const [internalOpen, setInternalOpen] = useState(open);
  const [model, setModel] = useState(organisation);
  const { updateLicenceDates } = useOrganisationService();

  const handleClose = (result: boolean) => {
    setInternalOpen(false);
    close(result);
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  useEffect(() => {
    setModel(organisation);
  }, [organisation]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose(false)}
        PaperComponent={Paper}
        aria-label={`extend licence dialog`}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="dialog-title">Extend licence</DialogTitle>
        <DialogContent>
          <FormCpt
            onSubmit={() => handleClose(true)}
            model={model}
            setModel={setModel}
            submitButtonText="Save"
            submit={updateLicenceDates}
            fullWithButton={false}
            actions={
              <Button
                autoFocus
                onClick={() => handleClose(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            }
          >
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="top"
              alignItems="stretch"
            >
              <Grid xs={12} item>
                <DtDateTimePickerInputCpt
                  type="date"
                  model={model}
                  setModel={setModel}
                  prop="licenceIssuedOn"
                  disabled
                  label="Licence Issued On"
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <DtDateTimePickerInputCpt
                  type="date"
                  model={model}
                  setModel={setModel}
                  prop="licenceExpiresOn"
                  label="Licence Expires On"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormCpt>
        </DialogContent>
      </Dialog>
    </>
  );
};
