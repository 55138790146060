import { Role } from "core/constants";
export const RoleDescriptionCpt = ({ roleId }: { roleId: number }) => {
  switch (roleId) {
    case Role.Presenter:
      return <>Presenter(s)</>;
    case Role.OrganisationAdmin:
      return <>Administrator(s)</>;
    default:
      return <>Participant(s)</>;
  }
};
