import { Validatable } from "core/models";
import { createElement } from "react";

export const appFormComponent = () => {
  return {
    errorMessage(model: Validatable, prop: string) {
      if (!prop || !model || !model.errors) {
        return "";
      }

      const messages = model.errors[prop];

      if (!messages || messages.length === 0) {
        return "";
      }

      if (messages.length === 1) {
        return messages[0] || "";
      }

      let listItems = [];
      for (let i in messages) {
        const message = messages[i];
        if (message) {
          listItems.push(createElement("li", null, message));
        }
      }

      if (!listItems || listItems.length <= 0) {
        return "";
      }

      return createElement("ul", null, listItems);
    },
    clearErrorMessage(model: Validatable, prop: string) {
      if (!prop || !model || !model.errors) {
        return;
      }

      const messages = model.errors[prop];

      if (!messages || messages.length === 0) {
        return;
      }

      delete model.errors[prop];
    },
    onChange(value: any, model: Validatable, prop: string) {
      if (!prop || !model || !model.errors) {
        return;
      }

      model.errors[prop] = null as any;
    },
  };
};
