/** @jsxImportSource @emotion/react */
import * as moment from "moment";
export const DateFormatterCpt = ({
  date,
  format = "DD/MM/YYYY HH:mm",
}: {
  date: any;
  format?: string;
}) => {
  if (!date) return null;

  return <span>{moment.utc(date).local().format(format)}</span>;
};
