import { createTheme, IUniDoodleTheme } from "@mui/material/styles";
import * as colors from "styles/colors";

export const theme: IUniDoodleTheme = createTheme({
  ...colors.palette,
  typography: {
    fontFamily: "'Nunito', sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Nunito', sans-serif;
          font-weight: 400;
          line-height: 24px
        }
      `,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "'Nunito', sans-serif",
          color: colors.palette.palette.secondary.main,
          fontWeight: 500,
          fontSize: "16px",
          fontStyle: "normal",
          lineHeight: "20px",
          letterSpacing: "0.02em",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: colors.palette.palette.blue.main,
          fontSize: 40,
          fontWeight: "bold",
        },
        h6: {
          color: colors.palette.palette.blue.main,
          fontWeight: "bold",
          fontSize: 18,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "38px",
          padding: "8px 36px",
          gap: "8px",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "24px",
          textTransform: "none",
        },
        sizeSmall: {
          fontWeight: 400,
          fontSize: "12px",
          textTransform: "none",
          padding: "4px 15px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: colors.palette.palette.lightBackground.main,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "16px",
          fontFamily: "Inter",
          fontWeight: "500",
          backgroundColor: colors.palette.palette.tableHeader.main,
          color: colors.palette.palette.tableHeader.contrastText,
          paddingTop: "8px !important",
          paddingBottom: "8px !important",
        },
        root: {
          borderBottom: 0,
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: "400",
          color: colors.palette.palette.secondary.main,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: colors.palette.palette.secondary.main,
          fontFamily: "Roboto",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "24px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.palette.palette.tableHeader.main}`,
          borderRadius: 1,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          textAlign: "center",
          display: "inline-block",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.palette.palette.blue.main,
          color: colors.palette.palette.blue.contrastText,
          position: "absolute",
          zIndex: 1000,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.palette.palette.blue.main,
          color: colors.palette.palette.blue.contrastText,
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          borderRadius: 4,
          "&.Mui-error": {
            border: `1px solid ${colors.palette.palette.error.main} !important`,
            borderRadius: 4,
          },
          "&:focus-within": {
            border: `1px solid ${colors.palette.palette.primary.main} !important`,
            borderRadius: 4,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.palette.palette.input.main,
          marginTop: "10px !important",
          "&:after": {
            border: "0px !important",
          },
          "&:before": {
            border: "0px !important",
          },
          "&.Mui-error": {
            border: `1px solid ${colors.palette.palette.error.main} !important`,
            borderRadius: 4,
          },
          "&:focus-within": {
            border: `1px solid ${colors.palette.palette.primary.main} !important`,
            borderRadius: 4,
          },
        },
        input: {
          position: "relative",
          backgroundColor: colors.palette.palette.input.main,
          fontSize: 16,
          padding: "10px 12px",
          width: "100%",
          borderRadius: 4,
          color: colors.palette.palette.input.contrastText,
          border: "0px",
          "&:disabled": {
            color: colors.palette.palette.inputDisabled.contrastText,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "0px !important",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          paddingTop: "10px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "5px",
          marginRight: "5px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          border: "0px !important",
        },
      },
    },
  },
});
