/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Alert,
  CircularProgress,
  Box,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { sharedLoadingState } from "core/atoms";
import { useNotificationProvider, useOrganisationService } from "core/hooks";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

interface AlertDialogProps {
  organisationId: number;
  roleId: number;
  open: boolean;
  close: Function;
}

export const UserFileUploadDialogCpt = (props: AlertDialogProps) => {
  const { organisationId, roleId, open, close } = props;
  const [internalOpen, setInternalOpen] = useState(open);
  const { uploadUserCsv } = useOrganisationService();
  const [isLoading, setSharedIsLoading] = useRecoilState(sharedLoadingState);
  const [message, setMessage] = useState("");
  const { success } = useNotificationProvider();

  const handleClose = (result = false) => {
    setMessage("");
    setInternalOpen(false);
    close(result);
  };

  const handleFileUpload = async ({ target }: { target: any }) => {
    setSharedIsLoading(true);
    setMessage("");
    try {
      const resp = await uploadUserCsv(organisationId, roleId, target.files[0]);
      const data = resp.data;
      if (data.isSuccess) {
        success("Users successfully uploaded");
        handleClose(true);
      } else {
        setMessage(data.message);
      }
    } catch (err: any) {
      if (err.response.data.errors.file.length > 0) {
        setMessage(err.response.data.errors.file[0]);
      } else {
        setMessage(err.message);
      }
    } finally {
      setSharedIsLoading(false);
    }
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose()}
        PaperComponent={Paper}
        aria-label="user upload dialog"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="user-upload-dialog-title">
          Please select a file to import
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button
                component="label"
                variant="text"
                startIcon={<UploadIcon />}
                fullWidth
                sx={{
                  marginBottom: 2,
                }}
              >
                Select file
                <input
                  hidden
                  accept=".csv"
                  id="userFileUpload"
                  onChange={handleFileUpload}
                  type="file"
                />
              </Button>
              {!message ? null : <Alert severity="info">{message}</Alert>}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            variant="outlined"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
