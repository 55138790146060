/** @jsxImportSource @emotion/react */
import {
  DateFormatterCpt,
  PageContainerCpt,
  PageTitleCpt,
} from "components/common";
import { useCallback, useState } from "react";
import {
  FilterOrganisationModelGridCollection,
  FilterOrganisationQuery,
} from "core/webapi";
import { GridFilter, useOrganisationService } from "core/hooks";
import { GridCpt, GridSearchInputCpt } from "components/grid";
import {
  Alert,
  Container,
  Grid,

  Link,
  TableCell,
  TableRow,
} from "@mui/material";

export const OrganisationGridView = () => {
  const { filterOrganisations } = useOrganisationService();
  const [data, setData] = useState({} as FilterOrganisationModelGridCollection);
  const [defaultFilter, setDefaultFilter] = useState(
    () =>
      new GridFilter({
        sortBy: "name",
        sortOrder: "asc",
        search: "",
      })
  );

  const updateFilter = async (value: string) => {
    setDefaultFilter((prevFilter) => ({
      ...prevFilter,
      search: value,
    }));
  };

  const refresh = useCallback(
    async (filter: FilterOrganisationQuery) => {
      const resp = await filterOrganisations(filter);
      setData(resp.data);
      return resp;
    },
    [filterOrganisations]
  );

  const headers = [
    { title: "Name", sortBy: "name" },
    { title: "Type", sortBy: "organisationTypeLookup.description" },
    { title: "Country", sortBy: "countryLookup.description" },
    { title: "Created", sortBy: "createdAt" },
    { title: "Expires", sortBy: "licenceExpiresAt" },
    { title: "Presenters" },
    { title: "Participants" },
  ];

  return (
    <PageContainerCpt>
      <PageTitleCpt>
        <Grid
          justifyContent="space-between"
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={6}>
            Organisations
          </Grid>
          <Grid item xs={6} paddingRight={2}>
            <Grid container justifyContent="flex-end" spacing={4}>
              <Grid item xs={12} textAlign="right">
                <GridSearchInputCpt
                  defaultFilter={defaultFilter}
                  onSearchChanged={updateFilter}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageTitleCpt>
      <Container component="div" maxWidth={false} sx={{ marginTop: 2 }}>
        <GridCpt
          refresh={refresh}
          defaultFilter={defaultFilter}
          headers={headers}
        >
          {data.items && data.items.length > 0 ? (
            data.items?.map((item) => (
              <TableRow key={item.organisationId}>
                <TableCell>
                  <Link
                    href={`/admin/licence/${item.organisationId}/0`}
                    underline="always"
                  >
                    {item.organisationName}
                  </Link>
                </TableCell>
                <TableCell>{item.organisationTypeName}</TableCell>
                <TableCell>{item.countryName}</TableCell>
                <TableCell>
                  <DateFormatterCpt date={item.createdAt} format="DD/MM/YYYY" />
                </TableCell>
                <TableCell>
                  <DateFormatterCpt date={item.expiresAt} format="DD/MM/YYYY" />
                </TableCell>
                <TableCell>
                  {item.presenters}/{item.limitPresenters}
                </TableCell>
                <TableCell>
                  {item.participants}/{item.limitParticipants}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Alert severity="info">
                  No organisations have been registered yet
                </Alert>
              </TableCell>
            </TableRow>
          )}
        </GridCpt>
      </Container>
    </PageContainerCpt>
  );
};
