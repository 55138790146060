import {
  Container,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { useState, useEffect } from "react";
import { GridCollection, GridFilter } from "core/hooks";
import { useGrid } from "core/hooks";
import { AxiosPromise } from "axios";
import { GridPaginationCpt } from "./GridPaginationCpt";

export interface GridProps {
  children: any;
  defaultFilter: GridFilter;
  headers: any[];
  ariaLabel?: string;
  shouldUpdateUrl?: boolean;
  refresh: (filter: any) => AxiosPromise<GridCollection<any>>;
}

export const GridCpt = (props: GridProps) => {
  const {
    children,
    defaultFilter,
    headers,
    ariaLabel = "table",
    shouldUpdateUrl = true,
    refresh,
  } = props;

  const [, loadData, getPagination, getFilter] = useGrid(
    defaultFilter,
    refresh,
    shouldUpdateUrl
  );
  const [internalFilter, setInternalFilter] = useState(() => getFilter());
  const [pagination, setPagination] = useState(() => getPagination());

  const handlePageChange = (value: number) => {
    const f = getFilter();
    f.page = value;
    setInternalFilter(f);
  };

  const handlePageSizeChanged = (value: number) => {
    const f = getFilter();
    f.pageSize = value;
    f.page = 1;
    setInternalFilter(f);
  };

  useEffect(() => {
    setPagination(getPagination());
  }, [getPagination]);

  useEffect(() => {
    loadData(internalFilter);
  }, [internalFilter, loadData]);

  useEffect(() => {
    const f = getFilter();
    setInternalFilter({ ...f, ...defaultFilter });
  }, [defaultFilter, getFilter]);

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label={ariaLabel}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      key={header.title}
                      align={header.align ? header.align : "left"}
                      size={header.size ? header.size : "medium"}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {header.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </Table>
            <GridPaginationCpt
              pagination={pagination}
              onPageChanged={handlePageChange}
              onPageSizeChanged={handlePageSizeChanged}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};
