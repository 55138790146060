/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";

interface AlertDialogProps {
  title: string;
  description: string;
  open: boolean;
  close: Function;
}

export const AlertDialogCpt = (props: AlertDialogProps) => {
  const { title, description, open, close } = props;
  const [internalOpen, setInternalOpen] = useState(open);

  const handleClose = () => {
    setInternalOpen(false);
    close();
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose()}
        PaperComponent={Paper}
        aria-label={`${title} dialog`}
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
