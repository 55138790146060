/** @jsxImportSource @emotion/react */
import { Input, InputAdornment } from "@mui/material";
import * as colors from "styles/colors";
import SearchIcon from "@mui/icons-material/Search";
import { GridFilter } from "core/hooks";

export interface GridSearchInputProps {
  defaultFilter: GridFilter;
  onSearchChanged: Function;
}

export const GridSearchInputCpt = (props: GridSearchInputProps) => {
  const { defaultFilter, onSearchChanged } = props;

  const updateFilter = async (e: any) => {
    onSearchChanged(e.target.value);
  };

  return (
    <Input
      size="small"
      sx={{
        paddingTop: 0,
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      css={{
        paddingLeft: "10px",
        borderRadius: 50,
        backgroundColor: colors.palette.palette.white.main,
        color: colors.palette.palette.secondary.main,
        marginTop: "0px !important",
        "&:focus-within": {
          borderRadius: 50,
        },
      }}
      inputProps={{
        style: {
          borderRadius: 50,
          backgroundColor: colors.palette.palette.white.main,
          color: colors.palette.palette.secondary.main,
        },
      }}
      id="gridSearch"
      name="gridSearch"
      placeholder="Search"
      onChange={updateFilter}
      value={defaultFilter.search || ""}
    />
  );
};
