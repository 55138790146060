import {
  Box,
  CssBaseline,
  Container,
  Grid,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { UnauthenticatedAppBarCpt } from "components/common";
// import { FooterCpt } from "components/footer";

export const RegisteringLayout = () => {
  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      }}
    >
      <CssBaseline />
      <UnauthenticatedAppBarCpt />
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            sx={{
              position: "relative",
              paddingLeft: "0 !important",
              paddingRight: "0 !important",
            }}
          >
            <Container
              component="div"
              maxWidth={false}
              sx={{
                marginTop: 2,
                marginX: 0,
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
              }}
            >
              <Outlet />
            </Container>
            {/* <FooterCpt></FooterCpt> */}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
