import { PageContainerCpt } from "components/common";
import {
  LicenceManagementCardCpt,
  OrganisationAllocationsCpt,
} from "components/organisation";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import * as colors from "styles/colors";
import { Divider } from "@mui/material";
import { usePermission } from "core/hooks";

function useRouteParams() {
  const params = useParams();
  return { tab: Number(params.tab), organisationId: params.organisationId };
}

export const LicenceManagementView = () => {
  const navigate = useNavigate();
  const params = useRouteParams();
  const [organisationIds, setOrganisationIds] = useState(
    () =>
      params.organisationId?.split(",").map((id: string) => Number(id)) || []
  );
  const [tab, setTab] = useState(params.tab);
  const { isSuperUser } = usePermission();

  useEffect(() => {
    setTab(params.tab);
  }, [params.tab]);

  useEffect(() => {
    setOrganisationIds(
      params.organisationId?.split(",").map((id: string) => Number(id)) || []
    );
  }, [params.organisationId]);

  const handleTabChanged = (newTab: number) => {
    navigate(`/admin/licence/${params.organisationId}/${newTab}`);
  };

  return (
    <>
      {organisationIds.map((organisationId: number, index: number) => (
        <React.Fragment key={organisationId}>
          <PageContainerCpt key={organisationId}>
            <LicenceManagementCardCpt organisationId={organisationId} />
            {isSuperUser() ? (
              <OrganisationAllocationsCpt
                tab={tab}
                organisationId={organisationId}
                shouldUpdateUrl={false}
                onTabChanged={handleTabChanged}
              />
            ) : null}
          </PageContainerCpt>
          {organisationIds.length <= 1 ||
          index === organisationIds.length - 1 ? null : (
            <Divider
              sx={{
                color: colors.palette.palette.primary.main,
                borderColor: colors.palette.palette.primary.main,
                borderBlockStartWidth: "thick",
              }}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
