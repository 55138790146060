/** @jsxImportSource @emotion/react */
import { Grid, Link } from "@mui/material";
import { useState } from "react";
import { LoginCommand } from "core/webapi";
import {
  DtPasswordTextInputCpt,
  DtTextInputCpt,
  FormCpt,
} from "components/form";
import { useAuth } from "core/hooks";
import { AccountBoxContainerCpt } from "components/account";

export const LoginFormView = () => {
  const { authenticate } = useAuth();

  const [model, setModel] = useState(() => new LoginCommand());

  return (
    <AccountBoxContainerCpt subTitle={"Log in to your account"}>
      <FormCpt
        model={model}
        setModel={setModel}
        submitButtonText="Login"
        submit={authenticate}
        avoidSuccessMessage={true}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          direction="row"
          justifyContent="top"
          alignItems="stretch"
          sx={{
            maxWidth: "500px",
            display: "inline-block",
          }}
        >
          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="email"
              label="Email"
              fullWidth
              type="email"
            />
          </Grid>
          <Grid xs={12} item>
            <DtPasswordTextInputCpt
              model={model}
              setModel={setModel}
              prop="password"
              label="Password"
              fullWidth
            />
          </Grid>
          <Grid
            xs={12}
            item
            sx={{
              textAlign: "end",
              paddingTop: "5px !important",
            }}
          >
            <Link
              href="/account/request/reset/password"
              sx={{
                textDecoration: "none",
              }}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </FormCpt>
    </AccountBoxContainerCpt>
  );
};
