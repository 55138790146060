/** @jsxImportSource @emotion/react */
import { TableCell } from "@mui/material";
import * as colors from "styles/colors";

export const AddUserTableCellCpt = ({
  children,
  colSpan,
  align = "left",
}: {
  children?: any;
  colSpan?: number;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}) => {
  return (
    <TableCell
      align={align}
      colSpan={colSpan ?? 1}
      sx={{
        backgroundColor: `${colors.palette.palette.white.main} !important`,
        color: colors.palette.palette.white.contrastText,
        fontFamily: "Roboto",
        fontSize: "16px",
        borderRight: 0,
        borderLeft: 0,
        borderTop: `1px solid ${colors.palette.palette.lightGray.main}`,
        borderBottom: `1px solid ${colors.palette.palette.lightGray.main}`,
      }}
    >
      {children}
    </TableCell>
  );
};
