/** @jsxImportSource @emotion/react */
import { useOrganisationService, usePermission } from "core/hooks";
import { useCallback, useEffect, useState } from "react";
import { sharedLoadingState } from "core/atoms";
import { useSetRecoilState } from "recoil";
import { OrganisationProfileModel } from "core/webapi";
import {
  PageTitleCpt,
  CardTitleCpt,
  CardCpt,
  CardBodyCpt,
  CardSubTitleCpt,
  DateFormatterCpt,
  AlertDialogCpt,
} from "components/common";
import { Grid, Button, Tooltip } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EditIcon from "@mui/icons-material/Edit";
import * as moment from "moment";
import { eventHub } from "core/providers";
import { DtTextConfirmInputCpt } from "components/form";
import { UpdateLicenceDatesDialogFormCpt } from "./UpdateLicenceDatesDialogFormCpt";
import { UpdateLicenceLimitsDialogFormCpt } from "./UpdateLicenceLimitsDialogFormCpt";

export const LicenceManagementCardCpt = ({
  organisationId,
}: {
  organisationId: number;
}) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const { getOrganisationProfileById, extendLicence, renameOrganisation } =
    useOrganisationService();
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const [model, setModel] = useState(() => new OrganisationProfileModel());
  const [expiryDate, setExpiryDate] = useState(() =>
    moment.utc(model.licenceExpiresAt)
  );

  const { isOrganisationAdmin, isSuperUser } = usePermission();
  const [openExtendLicenceDialog, setOpenExtendLicenceDialog] = useState(false);
  const [openEditLicenceDialog, setOpenEditLicenceDialog] = useState(false);
  const [openExtendLicenceAlert, setOpenExtendLicenceAlert] = useState(false);
  const cardMaxHeight = "calc(100% - 40px)";
  const closeExtendLicenceAlert = () => {
    setOpenExtendLicenceAlert(false);
  };

  const onCloseEditLicenceDialog = async (result: boolean) => {
    setOpenEditLicenceDialog(false);
    if (result) {
      await loadModel();
    }
  };

  const onCloseExtendLicenceDialog = async (result: boolean) => {
    setOpenExtendLicenceDialog(false);
    if (result) {
      await loadModel();
    }
  };

  const loadModel = useCallback(async () => {
    setSharedIsLoading(true);
    try {
      const resp = await getOrganisationProfileById(organisationId);
      setModel(resp.data);
    } finally {
      setSharedIsLoading(false);
    }
  }, [getOrganisationProfileById, organisationId, setSharedIsLoading]);

  useEffect(() => {
    eventHub.on("ORGANISATION_UPDATE", loadModel);
    loadModel();

    return () => {
      eventHub.remove("ORGANISATION_UPDATE", loadModel);
    };
  }, [loadModel]);

  useEffect(() => {
    setExpiryDate(moment.utc(model.licenceExpiresAt));
  }, [model.licenceExpiresAt]);

  const handleExtendLicence = async () => {
    setSharedIsLoading(true);
    try {
      await extendLicence({ organisationId });
      setOpenExtendLicenceAlert(true);
      await loadModel();
    } catch {
      setSharedIsLoading(false);
    }
  };

  const handleEditName = async () => {
    if (isEditingName) return;
    setIsEditingName(true);
  };

  const handleEditNameConfirm = async (result: boolean) => {
    setIsEditingName(false);
    if (result) {
      setSharedIsLoading(true);
      try {
        await renameOrganisation({
          organisationId,
          organisationName: model.name,
        });
        await loadModel();
      } finally {
        setSharedIsLoading(false);
      }
    }
  };

  return (
    <>
      <PageTitleCpt onClick={handleEditName}>
        {isEditingName ? (
          <DtTextConfirmInputCpt
            label="Organisation name"
            model={model}
            prop="name"
            onConfirm={handleEditNameConfirm}
            setModel={setModel}
            sx={{
              mr: 5,
            }}
          />
        ) : (
          <Tooltip title="Click to edit organisation name">
            <span>{model.name}</span>
          </Tooltip>
        )}
      </PageTitleCpt>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item xs={12} md={7}>
          <CardCpt
            sx={{
              height: "100% ",
            }}
          >
            <CardTitleCpt>Account overview</CardTitleCpt>
            <CardBodyCpt
              sx={{
                height: cardMaxHeight,
              }}
            >
              <Grid justifyContent="space-between" container spacing={1}>
                <Grid item xs={6}>
                  <CardSubTitleCpt>Country</CardSubTitleCpt>
                  {model.countryName}
                  <CardSubTitleCpt>Organisation Type</CardSubTitleCpt>
                  {model.organisationType}
                  <CardSubTitleCpt>Plan Type</CardSubTitleCpt>
                  {model.tierName}
                </Grid>
                <Grid item xs={6}>
                  <CardSubTitleCpt>Presenters</CardSubTitleCpt>
                  {model.currentPresenters}/{model.presenterLimit}
                  <CardSubTitleCpt>Participants</CardSubTitleCpt>
                  {model.currentParticipants}/{model.participantLimit}
                  {isSuperUser() ? (
                    <>
                      <CardSubTitleCpt>Org ID</CardSubTitleCpt>
                      {model.organisationKey}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </CardBodyCpt>
          </CardCpt>
        </Grid>
        <Grid item xs={12} md={5}>
          <CardCpt
            sx={{
              height: "100%",
            }}
          >
            <CardTitleCpt>Licence</CardTitleCpt>
            <CardBodyCpt
              sx={{
                height: cardMaxHeight,
              }}
            >
              <Grid justifyContent="space-between" container spacing={2}>
                <Grid item xs={12}>
                  <CardSubTitleCpt>First registered</CardSubTitleCpt>
                  <DateFormatterCpt
                    date={model.licenceFirstPurchasedAt}
                    format="MMM D, YYYY"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CardSubTitleCpt>License expiry</CardSubTitleCpt>
                  <DateFormatterCpt date={expiryDate} format="MMM D, YYYY" />
                </Grid>

                <Grid item xs={12} textAlign="end">
                  {isOrganisationAdmin() ? (
                    <Button
                      aria-label="Extend licence"
                      size="small"
                      onClick={handleExtendLicence}
                      variant="outlined"
                      color="secondary"
                      startIcon={<AdminPanelSettingsIcon />}
                    >
                      Extend licence
                    </Button>
                  ) : null}

                  {isSuperUser() ? (
                    <Grid justifyContent="end" container>
                      <Grid item xs={7}></Grid>
                      <Grid item xs={5}>
                        <Button
                          aria-label="Edit licence"
                          size="small"
                          onClick={() => setOpenExtendLicenceDialog(true)}
                          variant="outlined"
                          color="secondary"
                          startIcon={<AdminPanelSettingsIcon />}
                          fullWidth
                          sx={{
                            mt: 1,
                          }}
                        >
                          Extend licence
                        </Button>
                      </Grid>
                      <Grid item xs={7}></Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{
                          marginBottom: 2,
                        }}
                      >
                        <Button
                          aria-label="Edit licence"
                          size="small"
                          onClick={() => setOpenEditLicenceDialog(true)}
                          startIcon={<EditIcon />}
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          sx={{
                            mt: 1,
                          }}
                        >
                          Edit licence
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </CardBodyCpt>
          </CardCpt>
        </Grid>
      </Grid>

      <UpdateLicenceDatesDialogFormCpt
        open={openExtendLicenceDialog}
        close={onCloseExtendLicenceDialog}
        organisation={{
          organisationId: organisationId,
          licenceExpiresOn: expiryDate.format("YYYY-MM-DD"),
          licenceIssuedOn: moment
            .utc(model.licencePurchasedAt)
            .format("YYYY-MM-DD"),
        }}
      />

      <UpdateLicenceLimitsDialogFormCpt
        open={openEditLicenceDialog}
        close={onCloseEditLicenceDialog}
        organisation={{
          organisationId: organisationId,
          presentersLimit: model.presenterLimit,
          participantsLimit: model.participantLimit,
        }}
      />

      <AlertDialogCpt
        title="Congratulations"
        description="Payment is currently not required, you have just earned access for more 1 year"
        open={openExtendLicenceAlert}
        close={closeExtendLicenceAlert}
      />
    </>
  );
};
