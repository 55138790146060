/** @jsxImportSource @emotion/react */
import { Button, CircularProgress, TableCell, TableRow } from "@mui/material";
import { DtTextInputCpt } from "components/form";
import { useOrganisationService } from "core/hooks";
import { useEffect, useState } from "react";
import { CreateOrganisationUserCommand } from "core/webapi";
import * as colors from "styles/colors";
import CheckIcon from "@mui/icons-material/Check";

export const InternalTableCell = ({
  children,
  colSpan,
  align = "left",
}: {
  children?: any;
  colSpan?: number;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}) => {
  return (
    <TableCell
      align={align}
      colSpan={colSpan ?? 1}
      sx={{
        backgroundColor: `${colors.palette.palette.white.main} !important`,
        color: colors.palette.palette.white.contrastText,
        fontFamily: "Roboto",
        fontSize: "16px",
        borderRight: 0,
        borderLeft: 0,
        borderTop: `1px solid ${colors.palette.palette.lightGray.main}`,
        borderBottom: `1px solid ${colors.palette.palette.lightGray.main}`,
      }}
    >
      {children}
    </TableCell>
  );
};

interface AddUserTableRowProps {
  save: boolean;
  onSaved: Function;
  onRemove: Function;
  organisationId: number;
  roleId: number;
  index: number;
  disableDelete: boolean;
}

export const AddUserTableRowCpt = (props: AddUserTableRowProps) => {
  const {
    save,
    onSaved,
    onRemove,
    organisationId,
    roleId,
    index,
    disableDelete,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [model, setModel] = useState(
    new CreateOrganisationUserCommand({ organisationId, roleId })
  );
  const [saved, setSaved] = useState(false);
  const { createOrganisationUser } = useOrganisationService();
  const textBoxSx = {
    marginTop: "0px !important",
    marginBottom: "10px !important",
  };

  useEffect(() => {
    const saveItem = async () => {
      setIsSaving(true);
      try {
        await createOrganisationUser(model).handleValidationErrors(
          model,
          setModel
        );
        onSaved(true);
        setSaved(true);
      } catch (err: any) {
        onSaved(false);
      } finally {
        setIsSaving(false);
      }
    };
    if (save && !saved) {
      saveItem();
    }
  }, [createOrganisationUser, index, model, onSaved, save, saved]);

  return (
    <TableRow key={index}>
      <InternalTableCell>
        {saved ? (
          <CheckIcon color="success" />
        ) : isSaving ? (
          <CircularProgress />
        ) : (
          <>{index + 1}</>
        )}
      </InternalTableCell>
      <InternalTableCell>
        <DtTextInputCpt
          sx={textBoxSx}
          model={model}
          setModel={setModel}
          prop="firstname"
          fullWidth={true}
          label=""
        />
      </InternalTableCell>
      <InternalTableCell>
        <DtTextInputCpt
          sx={textBoxSx}
          model={model}
          setModel={setModel}
          prop="lastname"
          fullWidth={true}
          label=""
        />
      </InternalTableCell>
      <InternalTableCell>
        <DtTextInputCpt
          sx={textBoxSx}
          model={model}
          setModel={setModel}
          prop="email"
          fullWidth={true}
          label=""
        />
      </InternalTableCell>
      <InternalTableCell>
        <DtTextInputCpt
          sx={textBoxSx}
          model={model}
          setModel={setModel}
          prop="organisationSpecificIdentifier"
          fullWidth={true}
          label=""
        />
      </InternalTableCell>
      <InternalTableCell>
        <Button
          disabled={disableDelete || isSaving || saved || save}
          variant="text"
          color="secondary"
          size="small"
          onClick={() => onRemove(index)}
          sx={{
            fontFamily: "Roboto",
          }}
        >
          Delete
        </Button>
      </InternalTableCell>
    </TableRow>
  );
};
