/** @jsxImportSource @emotion/react */
import { Typography, Grid } from "@mui/material";
import { useState } from "react";
import { RegisterCommand } from "core/webapi";
import { useAccountService } from "core/hooks";
import {
  DtPasswordTextInputCpt,
  DtTextInputCpt,
  FormCpt,
} from "components/form";
import { YellowUnidoodleCpt } from "components/common";
import {
  RegisteringContainerCpt,
  CompleteRegistrationImageBoxCpt,
} from "components/account";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as colors from "styles/colors";

export const RegisterFormView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { register } = useAccountService();
  const [model, setModel] = useState(() => new RegisterCommand());
  model.tierId = Number(params.tierId);

  const handleSubmit = async (event: any, resp: any) => {
    navigate(`/register/confirmation/sent/${resp.data}`);
  };

  return (
    <RegisteringContainerCpt
      title={
        <>
          Ready to get started with <YellowUnidoodleCpt />?
        </>
      }
      subTitle={"Please complete your profile below"}
      sideBox={<CompleteRegistrationImageBoxCpt />}
    >
      <FormCpt
        onSubmit={handleSubmit}
        model={model}
        setModel={setModel}
        submitButtonText="Get started"
        submit={register}
        formFooter={
          <Typography
            variant="caption"
            component="span"
            sx={{
              textAlign: "start",
              color: colors.palette.palette.black.main,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            By clicking on "Get started" you agree to Sortly's Terms &
            Conditions and Privacy Policy
          </Typography>
        }
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          direction="row"
          justifyContent="top"
          alignItems="stretch"
        >
          <Grid xs={12} md={6} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="firstname"
              label="First name"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="lastname"
              label="Last name"
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="email"
              label="Email"
              fullWidth
              type="email"
            />
          </Grid>

          <Grid xs={12} item>
            <DtPasswordTextInputCpt
              model={model}
              setModel={setModel}
              prop="password"
              label="Password"
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <DtPasswordTextInputCpt
              model={model}
              setModel={setModel}
              prop="confirmPassword"
              label="Confirm Password"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormCpt>
    </RegisteringContainerCpt>
  );
};
