/** @jsxImportSource @emotion/react */
import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  NativeSelect,
} from "@mui/material";
import { GridPagination } from "core/hooks";
import { useEffect, useState } from "react";
import * as colors from "styles/colors";

export interface GridPaginationProps {
  pagination: GridPagination;
  onPageChanged: Function;
  onPageSizeChanged: Function;
}

export const GridPaginationCpt = (props: GridPaginationProps) => {
  const { pagination, onPageChanged, onPageSizeChanged } = props;
  const [pages, setPages] = useState([] as number[]);

  const handlePageChange = (value: number) => {
    onPageChanged(value);
  };

  const handlePageSizeChanged = (e: any) => {
    onPageSizeChanged(Number(e.target.value));
  };

  useEffect(() => {
    const pageNumbers = [];
    if (pagination.current === 1) {
      pageNumbers.push(1);
      while (
        pageNumbers.length < 3 &&
        pageNumbers.length < pagination.pageCount
      ) {
        pageNumbers.push(pageNumbers.length + 1);
      }
    } else if (pagination.current === pagination.pageCount) {
      for (let i = 2; i >= 0; i--) {
        let tempPage = pagination.current - i;
        if (tempPage > 0) pageNumbers.push(tempPage);
      }
    } else {
      pageNumbers.push(pagination.current - 1);
      pageNumbers.push(pagination.current);
      if (pagination.current < pagination.pageCount) {
        pageNumbers.push(pagination.current + 1);
      }
    }
    setPages(pageNumbers);
  }, [pagination]);

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: colors.palette.palette.lightBackground.main,
        borderTop: `1px solid ${colors.palette.palette.tableHeader.main}`,
        padding: 1,
      }}
    >
      <Grid justifyContent="space-between" container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            color: colors.palette.palette.black.main,
            fontSize: "10px",
            fontWeight: 400,
            fontFamily: "Roboto",
          }}
        >
          Show
          <NativeSelect
            id="page-entries"
            name="page-entries"
            value={pagination.pageSize}
            onChange={handlePageSizeChanged}
            sx={{
              marginRight: 1,
              marginLeft: 1,
              marginTop: "0px !important",
            }}
            inputProps={{
              style: {
                backgroundColor: colors.palette.palette.white.main,
                height: "15px",
                fontSize: "10px",
                paddingTop: "3px",
                paddingBottom: "8px",
              },
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </NativeSelect>
          entries
        </Grid>
        <Grid item xs={12} sm={6} textAlign="right">
          {!pagination.pageCount || pagination.pageCount <= 1 ? null : (
            <>
              <ButtonGroup
                size="small"
                color="secondary"
                aria-label="outlined primary button group"
                sx={{
                  backgroundColor:
                    colors.palette.palette.paginationBackground.main,
                  color:
                    colors.palette.palette.paginationBackground.contrastText,
                  border: `1px solid ${colors.palette.palette.paginationBackground.contrastText}`,
                }}
              >
                <Button
                  sx={{
                    borderRadius: 1,
                  }}
                  onClick={() => handlePageChange(pagination.current - 1)}
                  disabled={pagination.current === 1}
                >
                  Previous
                </Button>
                {pages.map((page) => (
                  <Button
                    key={page}
                    sx={
                      pagination.current === page
                        ? {
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }
                        : {}
                    }
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Button>
                ))}
                <Button
                  sx={{
                    borderRadius: 1,
                  }}
                  onClick={() => handlePageChange(pagination.current + 1)}
                  disabled={pagination.current === pagination.pageCount}
                >
                  Next
                </Button>
              </ButtonGroup>
              <p
                css={{
                  color: colors.palette.palette.black.main,
                  fontSize: "10px !important",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  margin: 0,
                }}
              >
                {pagination.pageCount} pages
              </p>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
