import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "core/providers/common/api.service";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { RecoilRoot } from "recoil";
import { theme } from "styles/theme";
import { NavigationRoutes } from "./Routes";
import * as moment from "moment";
window.Buffer = window.Buffer || require("buffer").Buffer;
moment.locale('en-ie');

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <NavigationRoutes />
        <App />
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
