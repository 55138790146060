/** @jsxImportSource @emotion/react */
import { Grid, Button, FormHelperText } from "@mui/material";
import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { useNotificationProvider } from "core/hooks";

export interface FormProps {
  children: any;
  actions?: any;
  formFooter?: any;
  model: any;
  setModel: Function;
  submitButtonText: string;
  submit: (model: any) => any;
  onSubmit?: Function;
  buttonVariant?: "text" | "outlined" | "contained" | undefined;
  buttonStartIcon?: any;
  buttonSize?: "small" | "medium" | "large" | undefined;
  buttonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "blue"
    | undefined;
  preventStopLoading?: boolean;
  fullWithButton?: boolean;
  avoidSuccessMessage?: boolean;
}

export const FormCpt = (props: FormProps) => {
  const {
    children,
    actions,
    formFooter,
    model,
    setModel,
    submitButtonText,
    submit,
    onSubmit,
    buttonVariant,
    buttonStartIcon,
    buttonSize,
    buttonColor,
    preventStopLoading,
    fullWithButton,
    avoidSuccessMessage,
  } = props;

  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const { success, warning } = useNotificationProvider();

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      setSharedIsLoading(true);
      try {
        const resp = await submit(model).handleValidationErrors(
          model,
          setModel
        );
        if (onSubmit) onSubmit(event, resp);
        if (!avoidSuccessMessage) {
          success("Data successfully saved");
        }
      } catch (err: any) {
        if (err?.response?.status !== 422) {
          warning("Something went wrong");
        }
        setSharedIsLoading(false);
      } finally {
        if (preventStopLoading === undefined || preventStopLoading === false) {
          setSharedIsLoading(false);
        }
      }
    },
    [
      model,
      onSubmit,
      preventStopLoading,
      setModel,
      setSharedIsLoading,
      submit,
      success,
      warning,
      avoidSuccessMessage,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit}
      css={{
        textAlign: "center",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: 1,
        }}
      >
        <Grid xs={12} item>
          {children}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="end"
        textAlign="end"
        sx={{
          padding: 1,
        }}
      >
        <Grid xs={12} item>
          {actions}
          <Button
            sx={{
              marginLeft: 1,
            }}
            fullWidth={fullWithButton || fullWithButton === undefined}
            type="submit"
            color={buttonColor || "primary"}
            variant={buttonVariant || "contained"}
            size={buttonSize}
            startIcon={buttonStartIcon}
          >
            {submitButtonText}
          </Button>
        </Grid>
        {formFooter ? (
          <Grid
            xs={12}
            item
            sx={{
              textAlign: "start",
              marginTop: 2,
            }}
          >
            {formFooter}
          </Grid>
        ) : null}
      </Grid>
      {!model.validationSummary ||
      model.validationSummary.length <= 0 ? null : (
        <FormHelperText>
          <ul>
            {model.validationSummary.map((val: string) => (
              <li>{val}</li>
            ))}
          </ul>
        </FormHelperText>
      )}
    </form>
  );
};
