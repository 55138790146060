/** @jsxImportSource @emotion/react */
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import * as colors from "styles/colors";
import { YellowUnidoodleCpt } from "components/common";
import CheckIcon from "@mui/icons-material/Check";
import image from "styles/register_image_2.png";

export const CheckGreenListItemIcon = () => {
  return (
    <ListItemIcon
      sx={{
        color: colors.palette.palette.success.main,
      }}
    >
      <CheckIcon />
    </ListItemIcon>
  );
};

export const InternalListItemButton = ({ children }: { children: any }) => {
  return (
    <ListItemButton
      sx={{
        paddingLeft: 0,
      }}
    >
      {children}
    </ListItemButton>
  );
};

export const CompleteRegistrationImageBoxCpt = () => {
  const boxSx = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
  const textSx = {
    width: 420,
    fontSize: "16px",
    textAlign: "start",
  };
  return (
    <>
      <Box
        sx={{
          pt: 5,
          ...boxSx,
        }}
      >
        <img alt="Complete Registering" src={image} width={textSx.width} />
      </Box>
      <Box
        sx={{
          pt: 4,
          ...boxSx,
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            width: textSx.width,
            marginTop: "0",
            textAlign: "start",
            fontSize: "22px",
          }}
        >
          Here’s what <YellowUnidoodleCpt /> can help you do:
        </Typography>
      </Box>
      <Box
        sx={{
          pt: 1,
          ...boxSx,
        }}
      >
        <Typography
          variant="subtitle1"
          component="span"
          sx={{
            color: colors.palette.palette.secondary.main,
            ...textSx,
          }}
        >
          <List>
            <ListItem disablePadding>
              <InternalListItemButton>
                <CheckGreenListItemIcon />
                <ListItemText primary="Gain insights into students’ learning" />
              </InternalListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <InternalListItemButton>
                <CheckGreenListItemIcon />
                <ListItemText primary="Catch misunderstandings as they form, before they can take root" />
              </InternalListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <InternalListItemButton>
                <CheckGreenListItemIcon />
                <ListItemText primary="Give all students a voice in class, even the quietest ones" />
              </InternalListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <InternalListItemButton>
                <CheckGreenListItemIcon />
                <ListItemText primary="Improve retention by reinforcing new concepts" />
              </InternalListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <InternalListItemButton>
                <CheckGreenListItemIcon />
                <ListItemText primary="Foster engagement, whether in-person or working remotely" />
              </InternalListItemButton>
            </ListItem>
          </List>
        </Typography>
      </Box>
    </>
  );
};
