import { Divider, IconButton, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import { DtTextInputCpt } from "./DtTextInputCpt";

interface TextConfirmInputProps {
  onConfirm: Function;
  model: any;
  setModel: Function;
  prop: string;
  label?: string;
  onChange?: (e: any) => {};
  sx?: any;
}

export const DtTextConfirmInputCpt = (props: TextConfirmInputProps) => {
  const { onConfirm, model, setModel, prop, label, onChange, sx } = props;
  const handleConfirm = (result: boolean) => {
    onConfirm(result);
  };

  return (
    <Paper
      component="div"
      sx={{
        ...sx,
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "inherit",
      }}
    >
      <DtTextInputCpt
        aria-label={label}
        label={label}
        model={model}
        setModel={setModel}
        prop={prop}
        onChange={onChange}
        fullWidth
      />
      <IconButton
        type="button"
        color="success"
        sx={{ p: "10px" }}
        aria-label="yes"
        onClick={() => handleConfirm(true)}
      >
        <CheckIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        type="button"
        color="error"
        sx={{ p: "10px" }}
        aria-label="no"
        onClick={() => handleConfirm(false)}
      >
        <BlockIcon />
      </IconButton>
    </Paper>
  );
};
