import { useEffect, useState } from "react";
import { FormControl, InputLabel, Input, FormHelperText } from "@mui/material";
import { appFormComponent } from "core/providers";

export const DtTextInputCpt = ({
  model,
  setModel,
  prop,
  label,
  type = "text",
  fullWidth = false,
  disabled = false,
  placeholder = "",
  onChange = (e: any) => {},
  sx = {},
}: {
  model: any;
  setModel: Function;
  prop: string;
  label?: string;
  type?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange?: Function;
  sx?: any;
}) => {
  const { errorMessage, clearErrorMessage } = appFormComponent();
  const [error, setError] = useState(() => errorMessage(model, prop));

  useEffect(() => {
    setError(errorMessage(model, prop));
  }, [setError, errorMessage, model, prop]);

  const updateModel = (e: any) => {
    let internalModel = Object.assign({}, model);
    internalModel[prop] = e.target.value;
    clearErrorMessage(internalModel, prop);
    setModel(internalModel);
    onChange(e);
  };

  return (
    <FormControl variant="standard" error={!!error} fullWidth={fullWidth}>
      {!label ? null : (
        <InputLabel shrink htmlFor={prop}>
          {label}
        </InputLabel>
      )}
      <Input
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        type={type}
        fullWidth={fullWidth}
        id={prop}
        name={prop}
        onChange={updateModel}
        value={model[prop] || ""}
        size="small"
        autoComplete={type === "password" ? "off" : "on"}
        sx={{...sx}}
      />
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
