/** @jsxImportSource @emotion/react */
import { Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { sharedLoadingState } from "core/atoms";
import { useSetRecoilState } from "recoil";
import {
  GetCompleteRegistrationLookupsModel,
  CompleteRegistrationCommand,
} from "core/webapi";
import { useAccountService, useAuth } from "core/hooks";
import {
  DtTextInputCpt,
  DtSelectInputCpt,
  FormCpt,
  DtButtonSelectInputCpt,
} from "components/form";
import {
  CompleteRegistrationImageBoxCpt,
  RegisteringContainerCpt,
} from "components/account";
import { useNavigate } from "react-router-dom";

export const CompleteRegistrationFormView = () => {
  const navigate = useNavigate();
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const { getCompleteRegistrationLookups, completeRegistration } =
    useAccountService();
  const [model, setModel] = useState(
    () => new CompleteRegistrationCommand({ organisationTypeId: 1 })
  );
  const [lookupModel, setLookupModel] = useState(
    new GetCompleteRegistrationLookupsModel({ countries: [] })
  );
  const { refresh } = useAuth();

  useEffect(() => {
    const loadLookups = async () => {
      setSharedIsLoading(true);
      try {
        const resp = await getCompleteRegistrationLookups();
        setLookupModel(resp.data);
        setModel({ ...resp.data.user, organisationTypeId: 1 });
      } finally {
        setSharedIsLoading(false);
      }
    };
    loadLookups();
  }, [getCompleteRegistrationLookups, setSharedIsLoading]);

  const handleSubmit = async (event: any, resp: any) => {
    await refresh();
    navigate("/register/completed");
  };

  return (
    <RegisteringContainerCpt
      title={"Complete your profile"}
      subTitle={`Complete profile text. Complete profile text. Complete profile text.
    Complete profile text.`}
      sideBox={<CompleteRegistrationImageBoxCpt />}
    >
      <FormCpt
        onSubmit={handleSubmit}
        model={model}
        setModel={setModel}
        submitButtonText="Complete profile"
        submit={completeRegistration}
        formFooter={
          <Typography
            variant="caption"
            component="small"
            sx={{
              textAlign: "start",
            }}
          >
            By clicking on "Complete profile" you agree to Sortly's Terms &
            Conditions and Privacy Policy
          </Typography>
        }
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          direction="row"
          justifyContent="top"
          alignItems="stretch"
        >
          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="email"
              label="Email"
              fullWidth
              type="email"
              disabled
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="firstname"
              label="First name"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="lastname"
              label="Last name"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={12} item>
            <DtButtonSelectInputCpt
              items={lookupModel.organisationTypes}
              model={model}
              setModel={setModel}
              prop="organisationTypeId"
              label="This account is for"
              fullWidth
            />
          </Grid>

          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="organisationName"
              label="Organisation name"
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="purchaserJobTitle"
              label="Purchaser job title/role"
              fullWidth
            />
          </Grid>

          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="phone"
              label="Phone"
              fullWidth
              type="tel"
            />
          </Grid>
          <Grid xs={12} item>
            <DtSelectInputCpt
              model={model}
              setModel={setModel}
              prop="countryId"
              label="Country"
              fullWidth
              items={lookupModel.countries}
              mandatory
            />
          </Grid>
          <Grid xs={12} item>
            <DtTextInputCpt
              model={model}
              setModel={setModel}
              prop="organisationSpecificIdentifier"
              label="Identifier (optional)"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormCpt>
    </RegisteringContainerCpt>
  );
};
