import { Box, CssBaseline, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { CardBodyCpt } from "components/common";
import * as colors from "styles/colors";
// import { FooterCpt } from "components/footer";

export const LoginLayout = () => {
  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      }}
    >
      <CssBaseline />
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: colors.palette.palette.lightBackground.main,
          height: "calc(100vh - 65px)",
          verticalAlign: "middle",
        }}
      >
        <CardBodyCpt
          backgroundColor={colors.palette.palette.white.main}
          sx={{
            border: `1px solid ${colors.palette.palette.lightBackground.main}`,
            marginTop: "80px",
          }}
        >
          <Outlet />
        </CardBodyCpt>
      </Box>
    </Container>
  );
};
