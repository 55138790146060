/* eslint-disable no-extend-native */
// Export needed to make this a component
// https://stackoverflow.com/questions/57132428/augmentations-for-the-global-scope-can-only-be-directly-nested-in-external-modul
export { };

declare global {
    interface Promise<T> {
        handleValidationErrors<TResult1 = T, TResult2 = never>(model: any, setModel: Function): Promise<TResult1 | TResult2>;
        getValidatableData<TResult1 = T, TResult2 = never>(): Promise<TResult1 | TResult2>;
        // handleValidationErrors<T extends Validatable>(model: any): Promise<AxiosResponse<T>>;
        // getValidatableData<T extends Validatable>(): Promise<T>;
    }
}

// This is used to automatically attach validation errors and validation summary to a model
Promise.prototype.handleValidationErrors = function (model: any, setModel: Function) {
    return this.then(
        response => Promise.resolve(response),
        error => {
            if (error?.response?.status === 422) {
                setModel({ ...model, errors: error.response.data?.errors, validationSummary: error.response.data?.validationSummary });
            }
            return Promise.reject(error);
        });
};

// This is used to extract data from the response and to automatically reset errors to an empty array on data fetch
Promise.prototype.getValidatableData = function () {
    return this.then(
        response => {
            const data = response.data;
            data.errors = {};
            data.validationSummary = [];
            return Promise.resolve({ ...data });
        },
        error => Promise.reject(error),
    );
};