/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Button, Divider, IconButton, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  ConfirmDialogCpt,
  RoleDescriptionCpt,
  StyledMenuCpt,
} from "components/common";
import { eventHub } from "core/providers";
import { useSetRecoilState } from "recoil";
import { sharedLoadingState } from "core/atoms";
import { UserFileUploadDialogCpt } from "./UserFileUploadDialogCpt";
import { AddUsersDialogFormCpt } from "./AddUsersDialogFormCpt";
import { Role } from "core/constants";
import { useNotificationProvider, useOrganisationService } from "core/hooks";

export const AddUserDropdownCpt = ({
  roleId,
  organisationId,
}: {
  roleId: number;
  organisationId: number;
}) => {
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const { removeAllOrganisationUsers } = useOrganisationService();
  const { success, error } = useNotificationProvider();

  const onCloseDeleteConfirmDialog = async (result: boolean) => {
    setShowDeleteConfirmDialog(false);
    if (result) {
      setSharedIsLoading(true);
      try {
        await removeAllOrganisationUsers({ organisationId, roleId });
        eventHub.dispatch("ORGANISATION_UPDATE", { organisationId, roleId });
        success("Users successfully deleted");
      } catch {
        error("Oops! Something went wrong. Please try again.");
      } finally {
        setSharedIsLoading(false);
      }
    }
  };

  const onCloseDialog = (result: boolean) => {
    setOpenImportDialog(false);
    setOpenAddDialog(false);
    setShowDeleteConfirmDialog(false);
    if (result) {
      eventHub.dispatch("ORGANISATION_UPDATE", { organisationId, roleId });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadFile = async ({ target }: { target: any }) => {
    setSharedIsLoading(true);
    try {
      const fileUrl = `${process.env.PUBLIC_URL}/csv/user_csv_template.csv`;
      const fileName = "user_csv_template.csv";

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      link.click();
      handleClose();
    } finally {
      setSharedIsLoading(false);
    }
  };

  const showAddDialog = () => {
    setOpenAddDialog(true);
    handleClose();
  };

  const showImportDialog = () => {
    setOpenImportDialog(true);
    handleClose();
  };

  const showDeleteDialog = () => {
    setShowDeleteConfirmDialog(true);
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls="user-button-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<AddIcon />}
        variant="contained"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        User
      </Button>
      <IconButton
        color="primary"
        aria-controls="user-button-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <PersonAddAltIcon />
      </IconButton>
      <StyledMenuCpt
        id="logout-menu"
        MenuListProps={{
          "aria-labelledby": "logout menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple onClick={showAddDialog}>
          Add new <RoleDescriptionCpt roleId={roleId} />
        </MenuItem>
        <MenuItem disableRipple onClick={showImportDialog}>
          Import from CSV
        </MenuItem>
        {roleId === Role.OrganisationAdmin ? null : (
          <MenuItem disableRipple onClick={showDeleteDialog}>
            Delete all <RoleDescriptionCpt roleId={roleId} />
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={downloadFile}>Download CSV template</MenuItem>
      </StyledMenuCpt>
      <UserFileUploadDialogCpt
        open={openImportDialog}
        close={onCloseDialog}
        organisationId={organisationId}
        roleId={roleId}
      />

      <AddUsersDialogFormCpt
        open={openAddDialog}
        close={onCloseDialog}
        organisationId={organisationId}
        roleId={roleId}
      />

      <ConfirmDialogCpt
        title="Are you sure?"
        description={
          <>
            Are you sure you want to delete all{" "}
            <RoleDescriptionCpt roleId={roleId} />?
          </>
        }
        open={showDeleteConfirmDialog}
        close={onCloseDeleteConfirmDialog}
      />
    </>
  );
};
