import { atom } from "recoil";

export const sharedLoadingState = atom({
  key: "sharedLoading",
  default: false,
});

export const sharedSnackbarState = atom({
  key: "sharedSnackbarState",
  default: {
    open: false,
    message: "",
    severity: "info",
  },
});

export const openDrawerState = atom({
  key: "openDrawerState",
  default: true,
});