/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  ButtonGroup,
  Button,
} from "@mui/material";
import { SimpleLookup } from "core/webapi";
import { DtTextInputCpt } from "./DtTextInputCpt";
import { appFormComponent } from "core/providers";
import * as colors from "styles/colors";

export const DtButtonSelectInputCpt = ({
  model,
  setModel,
  prop,
  label,
  items = [] as SimpleLookup[],
  valueProp = "id",
  textProp = "description",
  variant = "outlined",
  color = "primary",
  fullWidth = false,
  onChange = (e: any) => {},
}: {
  model: any;
  setModel: Function;
  prop: string;
  label: string;
  items?: any[] | SimpleLookup[] | null;
  valueProp?: string;
  textProp?: string;
  variant?: "outlined" | "text" | "contained" | undefined;
  color?:
    | "primary"
    | "inherit"
    | "blue"
    | "error"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | undefined;
  fullWidth?: boolean;
  onChange?: Function;
}) => {
  const { errorMessage, clearErrorMessage } = appFormComponent();
  const [error, setError] = useState(() => errorMessage(model, prop));
  const baseSx = {
    borderRadius: 1.5,
    margin: "1px",
    "&:hover": {
      borderWidth: 2,
      color: colors.palette.palette.primary.main,
      backgroundColor: colors.palette.palette.input.main,
    },
  };
  useEffect(() => {
    setError(errorMessage(model, prop));
  }, [setError, errorMessage, model, prop]);

  if (!items || items.length === 0) {
    return (
      <DtTextInputCpt
        model={model}
        setModel={setModel}
        prop={prop}
        label={label}
        fullWidth={fullWidth}
        onChange={onChange}
      />
    );
  }

  const updateModel = (value: any) => {
    let internalModel = Object.assign({}, model);
    internalModel[prop] = value;
    clearErrorMessage(internalModel, prop);
    setModel(internalModel);
    onChange(value);
  };

  return (
    <FormControl variant="standard" fullWidth={fullWidth} error={!!error}>
      <InputLabel
        htmlFor={prop}
        sx={{
          transform: "translate(0px, -9px) scale(0.75)",
        }}
      >
        {label}
      </InputLabel>
      <ButtonGroup
        id={prop}
        variant={variant}
        aria-label={label}
        sx={{
          "& > :not(:last-of-type)": {
            borderRightColor: `${colors.palette.palette.primary.main} !important`,
            borderTopRightRadius: `6px !important`,
            borderBottomRightRadius: `6px !important`,
          },
          "& > :not(:first-of-type)": {
            borderLeftColor: `${colors.palette.palette.primary.main} !important`,
            borderTopLeftRadius: `6px !important`,
            borderBottomLeftRadius: `6px !important`,
          },
        }}
      >
        {items.map((item) => (
          <Button
            sx={
              item[valueProp] === model[prop]
                ? {
                    borderWidth: 2,
                    ...baseSx,
                  }
                : {
                    borderWidth: 0,
                    color: colors.palette.palette.inputDisabled.contrastText,
                    backgroundColor: colors.palette.palette.input.main,
                    ...baseSx,
                  }
            }
            key={item[valueProp]}
            color={item[valueProp] === model[prop] ? color : undefined}
            onClick={() => updateModel(item[valueProp])}
          >
            {item[textProp]}
          </Button>
        ))}
      </ButtonGroup>
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
