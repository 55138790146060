/** @jsxImportSource @emotion/react */
import { Button, Alert, AlertTitle, Container } from "@mui/material";
import { useAccountService, useAuth } from "core/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { sharedLoadingState } from "core/atoms";
import { useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { useNotificationProvider } from "core/hooks";

export const EmailConfirmedView = () => {
  const setSharedIsLoading = useSetRecoilState(sharedLoadingState);
  const navigate = useNavigate();
  const params = useParams();
  const { confirmEmail, sendConfirmationEmail } = useAccountService();
  const { setToken } = useAuth();
  const [error, setError] = useState(false);
  const { success } = useNotificationProvider();

  useEffect(() => {
    const confirm = async () => {
      setSharedIsLoading(true);
      try {
        const resp = await confirmEmail({
          userId: Number(params.userId),
          token: params.token || "",
        });
        success("Your email has been confirmed");
        const data = resp.data;
        setToken(data.token);
        navigate("/register/complete");
      } catch {
        setError(true);
      } finally {
        setSharedIsLoading(false);
      }
    };

    confirm();
  }, [confirmEmail, navigate, params, setSharedIsLoading, setToken, success]);

  if (error) {
    const send = async (event: any) => {
      event.preventDefault();
      setSharedIsLoading(true);
      await sendConfirmationEmail({ userId: Number(params.userId) })
        .then(() => success("Email has been sent"))
        .finally(() => setSharedIsLoading(false));
    };

    return (
      <Container>
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={send}>
              Resend confirmation email
            </Button>
          }
        >
          <AlertTitle>Something went wrong</AlertTitle>
          Something went wrong when trying to confirm your email, please try
          again.
        </Alert>
      </Container>
    );
  }

  return null;
};
