/** @jsxImportSource @emotion/react */
import * as colors from "styles/colors";
export const YellowTextCpt = ({ children }: { children: any }) => {
  return (
    <span
      css={{
        color: colors.palette.palette.yellow.main,
      }}
    >
      {children}
    </span>
  );
};
