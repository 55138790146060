/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import { ReactComponent as Logo } from "styles/logo_unidoodle.svg";
import * as colors from "styles/colors";

interface AccountBoxContainerProps {
  children: any;
  subTitle: any;
}

export const AccountBoxContainerCpt = (props: AccountBoxContainerProps) => {
  const { children, subTitle } = props;
  return (
    <Container
      sx={{
        textAlign: "center",
      }}
    >
      <Logo
        css={{
          maxWidth: "220px",
          marginTop: 10,
        }}
      />
      <Typography
        variant="subtitle1"
        component="h2"
        sx={{
          color: colors.palette.palette.secondary.main,
          fontSize: "20px",
          marginTop: 5,
          marginBottom: 3,
          textAlign: "center",
          lineHeight: "24px",
          fontStyle: "normal",
        }}
      >
        {subTitle}
      </Typography>
      {children}
    </Container>
  );
};
