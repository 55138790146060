/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { DtTextInputCpt, FormCpt } from "components/form";
import { useUserService } from "core/hooks";
import { CreateSuperUserCommand } from "core/webapi";
import { useEffect, useState } from "react";
interface CreateSuperUserDialogFormProps {
  open: boolean;
  close: Function;
}

export const CreateSuperUserDialogFormCpt = (
  props: CreateSuperUserDialogFormProps
) => {
  const { open, close } = props;
  const [internalOpen, setInternalOpen] = useState(open);
  const [model, setModel] = useState(() => new CreateSuperUserCommand());
  const { createSuperUser } = useUserService();

  const handleClose = (result: boolean) => {
    setInternalOpen(false);
    setModel(new CreateSuperUserCommand());
    close(result);
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={() => handleClose(false)}
        PaperComponent={Paper}
        aria-label={`extend licence dialog`}
        maxWidth="xs"
      >
        <DialogTitle id="dialog-title">Create admin user</DialogTitle>
        <DialogContent>
          <FormCpt
            onSubmit={() => handleClose(true)}
            model={model}
            setModel={setModel}
            submitButtonText="Save"
            submit={createSuperUser}
            fullWithButton={false}
            actions={
              <Button
                autoFocus
                onClick={() => handleClose(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            }
          >
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="top"
              alignItems="stretch"
            >
              <Grid xs={12} item>
                <DtTextInputCpt
                  model={model}
                  setModel={setModel}
                  prop="firstname"
                  label="First name"
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <DtTextInputCpt
                  model={model}
                  setModel={setModel}
                  prop="lastname"
                  label="Last name"
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <DtTextInputCpt
                  model={model}
                  setModel={setModel}
                  prop="email"
                  label="Email"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormCpt>
        </DialogContent>
      </Dialog>
    </>
  );
};
