/* tslint:disable */
/* eslint-disable */
/**
 * May UniDoodle API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface CompleteRegistrationCommand
 */
export class CompleteRegistrationCommand {
  /**
   * User first name.
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  lastname: string = undefined as any;
  /**
   * Organisation Name
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  organisationName: string = undefined as any;
  /**
   * Purchaser Job Title
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  purchaserJobTitle: string = undefined as any;
  /**
   * Phone
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  phone: string = undefined as any;
  /**
   * Country Id
   * @type {number}
   * @memberof CompleteRegistrationCommand
   */
  countryId: number = undefined as any;
  /**
   * Organisation type
   * @type {number}
   * @memberof CompleteRegistrationCommand
   */
  organisationTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CompleteRegistrationCommand
   */
  organisationSpecificIdentifier: string = undefined as any;

  constructor(data?: Partial<CompleteRegistrationCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ConfirmEmailCommand
 */
export class ConfirmEmailCommand {
  /**
   *
   * @type {number}
   * @memberof ConfirmEmailCommand
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ConfirmEmailCommand
   */
  token: string = undefined as any;

  constructor(data?: Partial<ConfirmEmailCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CreateLogEventCommand
 */
export class CreateLogEventCommand {
  /**
   *
   * @type {string}
   * @memberof CreateLogEventCommand
   */
  timestamp: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CreateLogEventCommand
   */
  eventName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CreateLogEventCommand
   */
  organisationKey: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CreateLogEventCommand
   */
  userEmail: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CreateLogEventCommand
   */
  questionType: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CreateLogEventCommand
   */
  sessionDuration?: number | null = undefined as any;

  constructor(data?: Partial<CreateLogEventCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CreateOrganisationUserCommand
 */
export class CreateOrganisationUserCommand {
  /**
   *
   * @type {number}
   * @memberof CreateOrganisationUserCommand
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CreateOrganisationUserCommand
   */
  roleId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CreateOrganisationUserCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof CreateOrganisationUserCommand
   */
  lastname: string = undefined as any;
  /**
   * User email
   * @type {string}
   * @memberof CreateOrganisationUserCommand
   */
  email: string = undefined as any;
  /**
   * User Identifier
   * @type {string}
   * @memberof CreateOrganisationUserCommand
   */
  organisationSpecificIdentifier: string = undefined as any;

  constructor(data?: Partial<CreateOrganisationUserCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CreateSuperUserCommand
 */
export class CreateSuperUserCommand {
  /**
   * User first name.
   * @type {string}
   * @memberof CreateSuperUserCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof CreateSuperUserCommand
   */
  lastname: string = undefined as any;
  /**
   * User email
   * @type {string}
   * @memberof CreateSuperUserCommand
   */
  email: string = undefined as any;

  constructor(data?: Partial<CreateSuperUserCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DisableUserCommand
 */
export class DisableUserCommand {
  /**
   *
   * @type {number}
   * @memberof DisableUserCommand
   */
  userId: number = undefined as any;

  constructor(data?: Partial<DisableUserCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface EditUserCommand
 */
export class EditUserCommand {
  /**
   *
   * @type {Array<string>}
   * @memberof EditUserCommand
   */
  authorizedRoles?: Array<string> | null = undefined as any;
  /**
   * User Id
   * @type {number}
   * @memberof EditUserCommand
   */
  userId: number = undefined as any;
  /**
   * Organisation Id
   * @type {number}
   * @memberof EditUserCommand
   */
  organisationId: number = undefined as any;
  /**
   * User first name.
   * @type {string}
   * @memberof EditUserCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof EditUserCommand
   */
  lastname: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof EditUserCommand
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof EditUserCommand
   */
  organisationSpecificIdentifier: string = undefined as any;

  constructor(data?: Partial<EditUserCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExtendLicenceCommand
 */
export class ExtendLicenceCommand {
  /**
   *
   * @type {number}
   * @memberof ExtendLicenceCommand
   */
  organisationId: number = undefined as any;

  constructor(data?: Partial<ExtendLicenceCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterOrganisationAllocationsModel
 */
export class FilterOrganisationAllocationsModel {
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsModel
   */
  allocationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsModel
   */
  organisationSpecificIdentifier: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsModel
   */
  status: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterOrganisationAllocationsModel
   */
  canGetAnInvite: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterOrganisationAllocationsModel
   */
  isDeleted: boolean = undefined as any;

  constructor(data?: Partial<FilterOrganisationAllocationsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterOrganisationAllocationsModelGridCollection
 */
export class FilterOrganisationAllocationsModelGridCollection {
  /**
   *
   * @type {Array<FilterOrganisationAllocationsModel>}
   * @memberof FilterOrganisationAllocationsModelGridCollection
   */
  items?: Array<FilterOrganisationAllocationsModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(
    data?: Partial<FilterOrganisationAllocationsModelGridCollection>
  ) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterOrganisationAllocationsQuery
 */
export class FilterOrganisationAllocationsQuery {
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsQuery
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsQuery
   */
  roleId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterOrganisationAllocationsQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationAllocationsQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationAllocationsQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterOrganisationAllocationsQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterOrganisationModel
 */
export class FilterOrganisationModel {
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationModel
   */
  countryName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModel
   */
  organisationTypeId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationModel
   */
  organisationTypeName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationModel
   */
  createdAt: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationModel
   */
  expiresAt: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModel
   */
  limitPresenters: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModel
   */
  presenters: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModel
   */
  limitParticipants: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModel
   */
  participants: number = undefined as any;

  constructor(data?: Partial<FilterOrganisationModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterOrganisationModelGridCollection
 */
export class FilterOrganisationModelGridCollection {
  /**
   *
   * @type {Array<FilterOrganisationModel>}
   * @memberof FilterOrganisationModelGridCollection
   */
  items?: Array<FilterOrganisationModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterOrganisationModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterOrganisationQuery
 */
export class FilterOrganisationQuery {
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterOrganisationQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterOrganisationQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterOrganisationQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterOrganisationQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterUserModel
 */
export class FilterUserModel {
  /**
   *
   * @type {number}
   * @memberof FilterUserModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserModel
   */
  fullName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserModel
   */
  status: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserModel
   */
  role: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterUserModel
   */
  canGetAnInvite: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterUserModel
   */
  isDeleted: boolean = undefined as any;

  constructor(data?: Partial<FilterUserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterUserModelGridCollection
 */
export class FilterUserModelGridCollection {
  /**
   *
   * @type {Array<FilterUserModel>}
   * @memberof FilterUserModelGridCollection
   */
  items?: Array<FilterUserModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUserModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUserModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUserModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUserModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterUserModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterUserQuery
 */
export class FilterUserQuery {
  /**
   *
   * @type {number}
   * @memberof FilterUserQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUserQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterUserQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUserQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUserQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterUserQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompleteRegistrationLookupsModel
 */
export class GetCompleteRegistrationLookupsModel {
  /**
   *
   * @type {CompleteRegistrationCommand}
   * @memberof GetCompleteRegistrationLookupsModel
   */
  user: CompleteRegistrationCommand = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompleteRegistrationLookupsModel
   */
  countries?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompleteRegistrationLookupsModel
   */
  organisationTypes?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetCompleteRegistrationLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetLicenceLimitsModel
 */
export class GetLicenceLimitsModel {
  /**
   *
   * @type {number}
   * @memberof GetLicenceLimitsModel
   */
  limit: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetLicenceLimitsModel
   */
  current: number = undefined as any;

  constructor(data?: Partial<GetLicenceLimitsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetLicenceLimitsQuery
 */
export class GetLicenceLimitsQuery {
  /**
   *
   * @type {number}
   * @memberof GetLicenceLimitsQuery
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetLicenceLimitsQuery
   */
  roleId: number = undefined as any;

  constructor(data?: Partial<GetLicenceLimitsQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetOrganisationRegistrationCompletedForCurrentUserModel
 */
export class GetOrganisationRegistrationCompletedForCurrentUserModel {
  /**
   *
   * @type {string}
   * @memberof GetOrganisationRegistrationCompletedForCurrentUserModel
   */
  userName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetOrganisationRegistrationCompletedForCurrentUserModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetOrganisationRegistrationCompletedForCurrentUserModel
   */
  userEmail: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetOrganisationRegistrationCompletedForCurrentUserModel
   */
  presenters: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetOrganisationRegistrationCompletedForCurrentUserModel
   */
  participants: number = undefined as any;

  constructor(
    data?: Partial<GetOrganisationRegistrationCompletedForCurrentUserModel>
  ) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetTierLookupsModel
 */
export class GetTierLookupsModel {
  /**
   *
   * @type {Array<GetTierLookupsTierLookup>}
   * @memberof GetTierLookupsModel
   */
  tiers?: Array<GetTierLookupsTierLookup> | null = undefined as any;

  constructor(data?: Partial<GetTierLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetTierLookupsTierLookup
 */
export class GetTierLookupsTierLookup {
  /**
   *
   * @type {number}
   * @memberof GetTierLookupsTierLookup
   */
  presenterLimit?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetTierLookupsTierLookup
   */
  participantLimit?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetTierLookupsTierLookup
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetTierLookupsTierLookup
   */
  description: string = undefined as any;

  constructor(data?: Partial<GetTierLookupsTierLookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUserProfileAllocationModel
 */
export class GetUserProfileAllocationModel {
  /**
   *
   * @type {number}
   * @memberof GetUserProfileAllocationModel
   */
  roleId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileAllocationModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetUserProfileAllocationModel
   */
  organisationId: number = undefined as any;

  constructor(data?: Partial<GetUserProfileAllocationModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUserProfileModel
 */
export class GetUserProfileModel {
  /**
   *
   * @type {number}
   * @memberof GetUserProfileModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {Array<GetUserProfileAllocationModel>}
   * @memberof GetUserProfileModel
   */
  allocations?: Array<GetUserProfileAllocationModel> | null = undefined as any;

  constructor(data?: Partial<GetUserProfileModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface LoginCommand
 */
export class LoginCommand {
  /**
   * Email.
   * @type {string}
   * @memberof LoginCommand
   */
  email: string = undefined as any;
  /**
   * Password.
   * @type {string}
   * @memberof LoginCommand
   */
  password: string = undefined as any;

  constructor(data?: Partial<LoginCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface LoginResultModel
 */
export class LoginResultModel {
  /**
   *
   * @type {number}
   * @memberof LoginResultModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {TokenResponse}
   * @memberof LoginResultModel
   */
  token: TokenResponse = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof LoginResultModel
   */
  emailConfirmed: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof LoginResultModel
   */
  hasCompletedRegistration: boolean = undefined as any;

  constructor(data?: Partial<LoginResultModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ModelState
 */
export class ModelState {
  /**
   *
   * @type {boolean}
   * @memberof ModelState
   */
  isValid: boolean = undefined as any;
  /**
   *
   * @type {Array<string>}
   * @memberof ModelState
   */
  validationSummary?: Array<string> | null = undefined as any;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof ModelState
   */
  errors?: { [key: string]: Array<string> } | null = undefined as any;

  constructor(data?: Partial<ModelState>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OrganisationProfileModel
 */
export class OrganisationProfileModel {
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  organisationKey: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  tierId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  tierName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  purchaserJobTitle: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  phone: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  countryId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  countryName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  licencePlan: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  licenceFirstPurchasedAt: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  licencePurchasedAt: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  licenceExpiresAt: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  presenterLimit: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  currentPresenters: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  participantLimit: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  currentParticipants: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  currentAdmins: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationProfileModel
   */
  totalUsers: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationProfileModel
   */
  organisationType: string = undefined as any;

  constructor(data?: Partial<OrganisationProfileModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PurgeAndDisableUserCommand
 */
export class PurgeAndDisableUserCommand {
  /**
   *
   * @type {number}
   * @memberof PurgeAndDisableUserCommand
   */
  userId: number = undefined as any;

  constructor(data?: Partial<PurgeAndDisableUserCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface RegisterCommand
 */
export class RegisterCommand {
  /**
   * User first name.
   * @type {string}
   * @memberof RegisterCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof RegisterCommand
   */
  lastname: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof RegisterCommand
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof RegisterCommand
   */
  password: string = undefined as any;
  /**
   * Tier Id
   * @type {number}
   * @memberof RegisterCommand
   */
  tierId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof RegisterCommand
   */
  confirmPassword: string = undefined as any;

  constructor(data?: Partial<RegisterCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface RemoveAllOrganisationUsersCommand
 */
export class RemoveAllOrganisationUsersCommand {
  /**
   *
   * @type {number}
   * @memberof RemoveAllOrganisationUsersCommand
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof RemoveAllOrganisationUsersCommand
   */
  roleId: number = undefined as any;

  constructor(data?: Partial<RemoveAllOrganisationUsersCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface RemoveOrganisationUserCommand
 */
export class RemoveOrganisationUserCommand {
  /**
   *
   * @type {number}
   * @memberof RemoveOrganisationUserCommand
   */
  allocationId: number = undefined as any;

  constructor(data?: Partial<RemoveOrganisationUserCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface RenameOrganisationCommand
 */
export class RenameOrganisationCommand {
  /**
   *
   * @type {number}
   * @memberof RenameOrganisationCommand
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof RenameOrganisationCommand
   */
  organisationName: string = undefined as any;

  constructor(data?: Partial<RenameOrganisationCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ResendInviteCommand
 */
export class ResendInviteCommand {
  /**
   *
   * @type {number}
   * @memberof ResendInviteCommand
   */
  userId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ResendInviteCommand
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ResendInviteCommand
   */
  roleId: number = undefined as any;

  constructor(data?: Partial<ResendInviteCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ResetPasswordCommand
 */
export class ResetPasswordCommand {
  /**
   *
   * @type {number}
   * @memberof ResetPasswordCommand
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordCommand
   */
  token: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordCommand
   */
  password: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordCommand
   */
  confirmPassword: string = undefined as any;

  constructor(data?: Partial<ResetPasswordCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SendConfirmationEmailCommand
 */
export class SendConfirmationEmailCommand {
  /**
   *
   * @type {number}
   * @memberof SendConfirmationEmailCommand
   */
  userId: number = undefined as any;

  constructor(data?: Partial<SendConfirmationEmailCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SendResetPasswordEmailCommand
 */
export class SendResetPasswordEmailCommand {
  /**
   * Email.
   * @type {string}
   * @memberof SendResetPasswordEmailCommand
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SendResetPasswordEmailCommand
   */
  requestType: string = undefined as any;

  constructor(data?: Partial<SendResetPasswordEmailCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SimpleLookup
 */
export class SimpleLookup {
  /**
   *
   * @type {number}
   * @memberof SimpleLookup
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SimpleLookup
   */
  description: string = undefined as any;

  constructor(data?: Partial<SimpleLookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TokenResponse
 */
export class TokenResponse {
  /**
   * Raw, encoded access token.
   * @type {string}
   * @memberof TokenResponse
   */
  accessToken: string = undefined as any;
  /**
   * Refresh token (not in use ATM).
   * @type {string}
   * @memberof TokenResponse
   */
  refreshToken: string = undefined as any;
  /**
   * Access token type.
   * @type {string}
   * @memberof TokenResponse
   */
  tokenType: string = undefined as any;
  /**
   * Time span of token validity (in seconds).
   * @type {number}
   * @memberof TokenResponse
   */
  validFor: number = undefined as any;

  constructor(data?: Partial<TokenResponse>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UpdateLicenceCommand
 */
export class UpdateLicenceCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateLicenceCommand
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UpdateLicenceCommand
   */
  presentersLimit: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UpdateLicenceCommand
   */
  participantsLimit: number = undefined as any;

  constructor(data?: Partial<UpdateLicenceCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UpdateLicenceDatesCommand
 */
export class UpdateLicenceDatesCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateLicenceDatesCommand
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof UpdateLicenceDatesCommand
   */
  licenceIssuedOn: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof UpdateLicenceDatesCommand
   */
  licenceExpiresOn: string = undefined as any;

  constructor(data?: Partial<UpdateLicenceDatesCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UploadUserCsvModel
 */
export class UploadUserCsvModel {
  /**
   *
   * @type {boolean}
   * @memberof UploadUserCsvModel
   */
  isSuccess: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof UploadUserCsvModel
   */
  message: string = undefined as any;

  constructor(data?: Partial<UploadUserCsvModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Register user
     * @param {CompleteRegistrationCommand} [completeRegistrationCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeRegistration: async (
      completeRegistrationCommand?: CompleteRegistrationCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/complete/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeRegistrationCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register user
     * @param {ConfirmEmailCommand} [confirmEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmEmail: async (
      confirmEmailCommand?: ConfirmEmailCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/confirm/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        confirmEmailCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit user
     * @param {EditUserCommand} [editUserCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editUser: async (
      editUserCommand?: EditUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/edit/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get register user lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompleteRegistrationLookups: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/complete/registration/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get current user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserProfile: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/user/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh token
     * @param {number} userId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEditUser: async (
      userId: number,
      organisationId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getEditUser", "userId", userId);
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists("getEditUser", "organisationId", organisationId);
      const localVarPath = `/api/v1/account/edit/user/{userId}/{organisationId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(
          `{${"organisationId"}}`,
          encodeURIComponent(String(organisationId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Organisation Registration Completed For Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationRegistrationCompletedForCurrentUser: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/register/completed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile: async (
      userId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUserProfile", "userId", userId);
      const localVarPath = `/api/v1/account/user/profile/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login user
     * @param {LoginCommand} [loginCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      loginCommand?: LoginCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/refresh/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register user
     * @param {RegisterCommand} [registerCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (
      registerCommand?: RegisterCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send confirmation email
     * @param {ResendInviteCommand} [resendInviteCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendInvite: async (
      resendInviteCommand?: ResendInviteCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/resend/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resendInviteCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register user
     * @param {ResetPasswordCommand} [resetPasswordCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword: async (
      resetPasswordCommand?: ResetPasswordCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/reset/password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send confirmation email
     * @param {SendConfirmationEmailCommand} [sendConfirmationEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendConfirmationEmail: async (
      sendConfirmationEmailCommand?: SendConfirmationEmailCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/send/confirmation/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendConfirmationEmailCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register user
     * @param {SendResetPasswordEmailCommand} [sendResetPasswordEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendResetPasswordEmail: async (
      sendResetPasswordEmailCommand?: SendResetPasswordEmailCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/send/reset/password/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendResetPasswordEmailCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Register user
     * @param {CompleteRegistrationCommand} [completeRegistrationCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeRegistration(
      completeRegistrationCommand?: CompleteRegistrationCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.completeRegistration(
          completeRegistrationCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Register user
     * @param {ConfirmEmailCommand} [confirmEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmEmail(
      confirmEmailCommand?: ConfirmEmailCommand,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LoginResultModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(
        confirmEmailCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Edit user
     * @param {EditUserCommand} [editUserCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editUser(
      editUserCommand?: EditUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editUser(
        editUserCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get register user lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompleteRegistrationLookups(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCompleteRegistrationLookupsModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCompleteRegistrationLookups(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get current user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentUserProfile(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetUserProfileModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentUserProfile(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Refresh token
     * @param {number} userId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEditUser(
      userId: number,
      organisationId: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<EditUserCommand>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEditUser(
        userId,
        organisationId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Organisation Registration Completed For Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganisationRegistrationCompletedForCurrentUser(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganisationRegistrationCompletedForCurrentUserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganisationRegistrationCompletedForCurrentUser(
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get user profile
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfile(
      userId: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetUserProfileModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(
        userId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Login user
     * @param {LoginCommand} [loginCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginCommand?: LoginCommand,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LoginResultModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        loginCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Register user
     * @param {RegisterCommand} [registerCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      registerCommand?: RegisterCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(
        registerCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Send confirmation email
     * @param {ResendInviteCommand} [resendInviteCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resendInvite(
      resendInviteCommand?: ResendInviteCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resendInvite(
        resendInviteCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Register user
     * @param {ResetPasswordCommand} [resetPasswordCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPassword(
      resetPasswordCommand?: ResetPasswordCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(
        resetPasswordCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Send confirmation email
     * @param {SendConfirmationEmailCommand} [sendConfirmationEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendConfirmationEmail(
      sendConfirmationEmailCommand?: SendConfirmationEmailCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sendConfirmationEmail(
          sendConfirmationEmailCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Register user
     * @param {SendResetPasswordEmailCommand} [sendResetPasswordEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendResetPasswordEmail(
      sendResetPasswordEmailCommand?: SendResetPasswordEmailCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sendResetPasswordEmail(
          sendResetPasswordEmailCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     *
     * @summary Register user
     * @param {CompleteRegistrationCommand} [completeRegistrationCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeRegistration(
      completeRegistrationCommand?: CompleteRegistrationCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .completeRegistration(completeRegistrationCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register user
     * @param {ConfirmEmailCommand} [confirmEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmEmail(
      confirmEmailCommand?: ConfirmEmailCommand,
      options?: any
    ): AxiosPromise<LoginResultModel> {
      return localVarFp
        .confirmEmail(confirmEmailCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Edit user
     * @param {EditUserCommand} [editUserCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editUser(
      editUserCommand?: EditUserCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .editUser(editUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get register user lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompleteRegistrationLookups(
      options?: any
    ): AxiosPromise<GetCompleteRegistrationLookupsModel> {
      return localVarFp
        .getCompleteRegistrationLookups(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get current user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserProfile(options?: any): AxiosPromise<GetUserProfileModel> {
      return localVarFp
        .getCurrentUserProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh token
     * @param {number} userId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEditUser(
      userId: number,
      organisationId: number,
      options?: any
    ): AxiosPromise<EditUserCommand> {
      return localVarFp
        .getEditUser(userId, organisationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Organisation Registration Completed For Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationRegistrationCompletedForCurrentUser(
      options?: any
    ): AxiosPromise<GetOrganisationRegistrationCompletedForCurrentUserModel> {
      return localVarFp
        .getOrganisationRegistrationCompletedForCurrentUser(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(
      userId: number,
      options?: any
    ): AxiosPromise<GetUserProfileModel> {
      return localVarFp
        .getUserProfile(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login user
     * @param {LoginCommand} [loginCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(
      loginCommand?: LoginCommand,
      options?: any
    ): AxiosPromise<LoginResultModel> {
      return localVarFp
        .login(loginCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(options?: any): AxiosPromise<TokenResponse> {
      return localVarFp
        .refreshToken(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register user
     * @param {RegisterCommand} [registerCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      registerCommand?: RegisterCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .register(registerCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send confirmation email
     * @param {ResendInviteCommand} [resendInviteCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendInvite(
      resendInviteCommand?: ResendInviteCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resendInvite(resendInviteCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register user
     * @param {ResetPasswordCommand} [resetPasswordCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      resetPasswordCommand?: ResetPasswordCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetPassword(resetPasswordCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send confirmation email
     * @param {SendConfirmationEmailCommand} [sendConfirmationEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendConfirmationEmail(
      sendConfirmationEmailCommand?: SendConfirmationEmailCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .sendConfirmationEmail(sendConfirmationEmailCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register user
     * @param {SendResetPasswordEmailCommand} [sendResetPasswordEmailCommand] User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendResetPasswordEmail(
      sendResetPasswordEmailCommand?: SendResetPasswordEmailCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .sendResetPasswordEmail(sendResetPasswordEmailCommand, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - interface
 * @export
 * @interface AccountApi
 */
export interface AccountApiInterface {
  /**
   *
   * @summary Register user
   * @param {CompleteRegistrationCommand} [completeRegistrationCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  completeRegistration(
    completeRegistrationCommand?: CompleteRegistrationCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Register user
   * @param {ConfirmEmailCommand} [confirmEmailCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  confirmEmail(
    confirmEmailCommand?: ConfirmEmailCommand,
    options?: any
  ): AxiosPromise<LoginResultModel>;

  /**
   *
   * @summary Edit user
   * @param {EditUserCommand} [editUserCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  editUser(
    editUserCommand?: EditUserCommand,
    options?: any
  ): AxiosPromise<void>;

  /**
   *
   * @summary Get register user lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  getCompleteRegistrationLookups(
    options?: any
  ): AxiosPromise<GetCompleteRegistrationLookupsModel>;

  /**
   *
   * @summary Get current user profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  getCurrentUserProfile(options?: any): AxiosPromise<GetUserProfileModel>;

  /**
   *
   * @summary Refresh token
   * @param {number} userId
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  getEditUser(
    userId: number,
    organisationId: number,
    options?: any
  ): AxiosPromise<EditUserCommand>;

  /**
   *
   * @summary Get Organisation Registration Completed For Current User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  getOrganisationRegistrationCompletedForCurrentUser(
    options?: any
  ): AxiosPromise<GetOrganisationRegistrationCompletedForCurrentUserModel>;

  /**
   *
   * @summary Get user profile
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  getUserProfile(
    userId: number,
    options?: any
  ): AxiosPromise<GetUserProfileModel>;

  /**
   *
   * @summary Login user
   * @param {LoginCommand} [loginCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  login(
    loginCommand?: LoginCommand,
    options?: any
  ): AxiosPromise<LoginResultModel>;

  /**
   *
   * @summary Refresh token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  refreshToken(options?: any): AxiosPromise<TokenResponse>;

  /**
   *
   * @summary Register user
   * @param {RegisterCommand} [registerCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  register(
    registerCommand?: RegisterCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Send confirmation email
   * @param {ResendInviteCommand} [resendInviteCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  resendInvite(
    resendInviteCommand?: ResendInviteCommand,
    options?: any
  ): AxiosPromise<void>;

  /**
   *
   * @summary Register user
   * @param {ResetPasswordCommand} [resetPasswordCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  resetPassword(
    resetPasswordCommand?: ResetPasswordCommand,
    options?: any
  ): AxiosPromise<void>;

  /**
   *
   * @summary Send confirmation email
   * @param {SendConfirmationEmailCommand} [sendConfirmationEmailCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  sendConfirmationEmail(
    sendConfirmationEmailCommand?: SendConfirmationEmailCommand,
    options?: any
  ): AxiosPromise<void>;

  /**
   *
   * @summary Register user
   * @param {SendResetPasswordEmailCommand} [sendResetPasswordEmailCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  sendResetPasswordEmail(
    sendResetPasswordEmailCommand?: SendResetPasswordEmailCommand,
    options?: any
  ): AxiosPromise<void>;
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI implements AccountApiInterface {
  /**
   *
   * @summary Register user
   * @param {CompleteRegistrationCommand} [completeRegistrationCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public completeRegistration(
    completeRegistrationCommand?: CompleteRegistrationCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .completeRegistration(completeRegistrationCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register user
   * @param {ConfirmEmailCommand} [confirmEmailCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public confirmEmail(
    confirmEmailCommand?: ConfirmEmailCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .confirmEmail(confirmEmailCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit user
   * @param {EditUserCommand} [editUserCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public editUser(editUserCommand?: EditUserCommand, options?: any) {
    return AccountApiFp(this.configuration)
      .editUser(editUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get register user lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getCompleteRegistrationLookups(options?: any) {
    return AccountApiFp(this.configuration)
      .getCompleteRegistrationLookups(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get current user profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getCurrentUserProfile(options?: any) {
    return AccountApiFp(this.configuration)
      .getCurrentUserProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh token
   * @param {number} userId
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getEditUser(userId: number, organisationId: number, options?: any) {
    return AccountApiFp(this.configuration)
      .getEditUser(userId, organisationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Organisation Registration Completed For Current User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getOrganisationRegistrationCompletedForCurrentUser(options?: any) {
    return AccountApiFp(this.configuration)
      .getOrganisationRegistrationCompletedForCurrentUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getUserProfile(userId: number, options?: any) {
    return AccountApiFp(this.configuration)
      .getUserProfile(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login user
   * @param {LoginCommand} [loginCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public login(loginCommand?: LoginCommand, options?: any) {
    return AccountApiFp(this.configuration)
      .login(loginCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public refreshToken(options?: any) {
    return AccountApiFp(this.configuration)
      .refreshToken(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register user
   * @param {RegisterCommand} [registerCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public register(registerCommand?: RegisterCommand, options?: any) {
    return AccountApiFp(this.configuration)
      .register(registerCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send confirmation email
   * @param {ResendInviteCommand} [resendInviteCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public resendInvite(
    resendInviteCommand?: ResendInviteCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .resendInvite(resendInviteCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register user
   * @param {ResetPasswordCommand} [resetPasswordCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public resetPassword(
    resetPasswordCommand?: ResetPasswordCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .resetPassword(resetPasswordCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send confirmation email
   * @param {SendConfirmationEmailCommand} [sendConfirmationEmailCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public sendConfirmationEmail(
    sendConfirmationEmailCommand?: SendConfirmationEmailCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .sendConfirmationEmail(sendConfirmationEmailCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register user
   * @param {SendResetPasswordEmailCommand} [sendResetPasswordEmailCommand] User data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public sendResetPasswordEmail(
    sendResetPasswordEmailCommand?: SendResetPasswordEmailCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .sendResetPasswordEmail(sendResetPasswordEmailCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AllocationProcessApi - axios parameter creator
 * @export
 */
export const AllocationProcessApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Check if there have been errors with the allocation process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkError: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/allocation/process/check/error`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Run allocation process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/allocation/process/run`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AllocationProcessApi - functional programming interface
 * @export
 */
export const AllocationProcessApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AllocationProcessApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check if there have been errors with the allocation process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkError(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkError(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Run allocation process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async run(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.run(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AllocationProcessApi - factory interface
 * @export
 */
export const AllocationProcessApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AllocationProcessApiFp(configuration);
  return {
    /**
     *
     * @summary Check if there have been errors with the allocation process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkError(options?: any): AxiosPromise<void> {
      return localVarFp
        .checkError(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Run allocation process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run(options?: any): AxiosPromise<void> {
      return localVarFp
        .run(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AllocationProcessApi - interface
 * @export
 * @interface AllocationProcessApi
 */
export interface AllocationProcessApiInterface {
  /**
   *
   * @summary Check if there have been errors with the allocation process
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllocationProcessApiInterface
   */
  checkError(options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Run allocation process
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllocationProcessApiInterface
   */
  run(options?: any): AxiosPromise<void>;
}

/**
 * AllocationProcessApi - object-oriented interface
 * @export
 * @class AllocationProcessApi
 * @extends {BaseAPI}
 */
export class AllocationProcessApi
  extends BaseAPI
  implements AllocationProcessApiInterface
{
  /**
   *
   * @summary Check if there have been errors with the allocation process
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllocationProcessApi
   */
  public checkError(options?: any) {
    return AllocationProcessApiFp(this.configuration)
      .checkError(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Run allocation process
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllocationProcessApi
   */
  public run(options?: any) {
    return AllocationProcessApiFp(this.configuration)
      .run(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LogEventApi - axios parameter creator
 * @export
 */
export const LogEventApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create log event
     * @param {CreateLogEventCommand} [createLogEventCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogEvent: async (
      createLogEventCommand?: CreateLogEventCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/log/event`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLogEventCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogEventApi - functional programming interface
 * @export
 */
export const LogEventApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LogEventApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create log event
     * @param {CreateLogEventCommand} [createLogEventCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLogEvent(
      createLogEventCommand?: CreateLogEventCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLogEvent(
        createLogEventCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * LogEventApi - factory interface
 * @export
 */
export const LogEventApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LogEventApiFp(configuration);
  return {
    /**
     *
     * @summary Create log event
     * @param {CreateLogEventCommand} [createLogEventCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogEvent(
      createLogEventCommand?: CreateLogEventCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createLogEvent(createLogEventCommand, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LogEventApi - interface
 * @export
 * @interface LogEventApi
 */
export interface LogEventApiInterface {
  /**
   *
   * @summary Create log event
   * @param {CreateLogEventCommand} [createLogEventCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogEventApiInterface
   */
  createLogEvent(
    createLogEventCommand?: CreateLogEventCommand,
    options?: any
  ): AxiosPromise<void>;
}

/**
 * LogEventApi - object-oriented interface
 * @export
 * @class LogEventApi
 * @extends {BaseAPI}
 */
export class LogEventApi extends BaseAPI implements LogEventApiInterface {
  /**
   *
   * @summary Create log event
   * @param {CreateLogEventCommand} [createLogEventCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogEventApi
   */
  public createLogEvent(
    createLogEventCommand?: CreateLogEventCommand,
    options?: any
  ) {
    return LogEventApiFp(this.configuration)
      .createLogEvent(createLogEventCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Organisation User
     * @param {CreateOrganisationUserCommand} [createOrganisationUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganisationUser: async (
      createOrganisationUserCommand?: CreateOrganisationUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/create/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrganisationUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Extend Licence
     * @param {ExtendLicenceCommand} [extendLicenceCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendLicence: async (
      extendLicenceCommand?: ExtendLicenceCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/extend/licence`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        extendLicenceCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters users in organisation.
     * @param {FilterOrganisationAllocationsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOrganisationAllocations: async (
      query?: FilterOrganisationAllocationsQuery,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/filter/allocations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters organisations.
     * @param {FilterOrganisationQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOrganisations: async (
      query?: FilterOrganisationQuery,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserOrganisationsProfile: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get organisation license limits and current
     * @param {GetLicenceLimitsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLicenceLimits: async (
      query?: GetLicenceLimitsQuery,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/license/limits`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationProfileById: async (
      organisationId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists(
        "getOrganisationProfileById",
        "organisationId",
        organisationId
      );
      const localVarPath =
        `/api/v1/organisation/profile/{organisationId}`.replace(
          `{${"organisationId"}}`,
          encodeURIComponent(String(organisationId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tier lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTierLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/tier/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove All Organisation Users
     * @param {RemoveAllOrganisationUsersCommand} [removeAllOrganisationUsersCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAllOrganisationUsers: async (
      removeAllOrganisationUsersCommand?: RemoveAllOrganisationUsersCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/remove/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        removeAllOrganisationUsersCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove User
     * @param {RemoveOrganisationUserCommand} [removeOrganisationUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganisationUser: async (
      removeOrganisationUserCommand?: RemoveOrganisationUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/remove/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        removeOrganisationUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Rename Organisation
     * @param {RenameOrganisationCommand} [renameOrganisationCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameOrganisation: async (
      renameOrganisationCommand?: RenameOrganisationCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/rename`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        renameOrganisationCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Licence
     * @param {UpdateLicenceCommand} [updateLicenceCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLicence: async (
      updateLicenceCommand?: UpdateLicenceCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/update/licence`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLicenceCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Licence Dates
     * @param {UpdateLicenceDatesCommand} [updateLicenceDatesCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLicenceDates: async (
      updateLicenceDatesCommand?: UpdateLicenceDatesCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/update/licence/dates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLicenceDatesCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload user csv file
     * @param {number} [organisationId]
     * @param {number} [roleId]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUserCsv: async (
      organisationId?: number,
      roleId?: number,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisation/upload/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (organisationId !== undefined) {
        localVarQueryParameter["OrganisationId"] = organisationId;
      }

      if (roleId !== undefined) {
        localVarQueryParameter["RoleId"] = roleId;
      }

      if (file !== undefined) {
        localVarFormParams.append("File", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrganisationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Organisation User
     * @param {CreateOrganisationUserCommand} [createOrganisationUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrganisationUser(
      createOrganisationUserCommand?: CreateOrganisationUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOrganisationUser(
          createOrganisationUserCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Extend Licence
     * @param {ExtendLicenceCommand} [extendLicenceCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendLicence(
      extendLicenceCommand?: ExtendLicenceCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.extendLicence(
        extendLicenceCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Filters users in organisation.
     * @param {FilterOrganisationAllocationsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterOrganisationAllocations(
      query?: FilterOrganisationAllocationsQuery,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FilterOrganisationAllocationsModelGridCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterOrganisationAllocations(
          query,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Filters organisations.
     * @param {FilterOrganisationQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterOrganisations(
      query?: FilterOrganisationQuery,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FilterOrganisationModelGridCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterOrganisations(query, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentUserOrganisationsProfile(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<OrganisationProfileModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentUserOrganisationsProfile(
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get organisation license limits and current
     * @param {GetLicenceLimitsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLicenceLimits(
      query?: GetLicenceLimitsQuery,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetLicenceLimitsModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLicenceLimits(query, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get user profile
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganisationProfileById(
      organisationId: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<OrganisationProfileModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganisationProfileById(
          organisationId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Tier lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTierLookups(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetTierLookupsModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTierLookups(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Remove All Organisation Users
     * @param {RemoveAllOrganisationUsersCommand} [removeAllOrganisationUsersCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeAllOrganisationUsers(
      removeAllOrganisationUsersCommand?: RemoveAllOrganisationUsersCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeAllOrganisationUsers(
          removeAllOrganisationUsersCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Remove User
     * @param {RemoveOrganisationUserCommand} [removeOrganisationUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeOrganisationUser(
      removeOrganisationUserCommand?: RemoveOrganisationUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeOrganisationUser(
          removeOrganisationUserCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Rename Organisation
     * @param {RenameOrganisationCommand} [renameOrganisationCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async renameOrganisation(
      renameOrganisationCommand?: RenameOrganisationCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.renameOrganisation(
          renameOrganisationCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update Licence
     * @param {UpdateLicenceCommand} [updateLicenceCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLicence(
      updateLicenceCommand?: UpdateLicenceCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicence(
        updateLicenceCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update Licence Dates
     * @param {UpdateLicenceDatesCommand} [updateLicenceDatesCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLicenceDates(
      updateLicenceDatesCommand?: UpdateLicenceDatesCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLicenceDates(
          updateLicenceDatesCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Upload user csv file
     * @param {number} [organisationId]
     * @param {number} [roleId]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadUserCsv(
      organisationId?: number,
      roleId?: number,
      file?: any,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UploadUserCsvModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUserCsv(
        organisationId,
        roleId,
        file,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrganisationApiFp(configuration);
  return {
    /**
     *
     * @summary Create Organisation User
     * @param {CreateOrganisationUserCommand} [createOrganisationUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganisationUser(
      createOrganisationUserCommand?: CreateOrganisationUserCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .createOrganisationUser(createOrganisationUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Extend Licence
     * @param {ExtendLicenceCommand} [extendLicenceCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendLicence(
      extendLicenceCommand?: ExtendLicenceCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .extendLicence(extendLicenceCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Filters users in organisation.
     * @param {FilterOrganisationAllocationsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOrganisationAllocations(
      query?: FilterOrganisationAllocationsQuery,
      options?: any
    ): AxiosPromise<FilterOrganisationAllocationsModelGridCollection> {
      return localVarFp
        .filterOrganisationAllocations(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Filters organisations.
     * @param {FilterOrganisationQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOrganisations(
      query?: FilterOrganisationQuery,
      options?: any
    ): AxiosPromise<FilterOrganisationModelGridCollection> {
      return localVarFp
        .filterOrganisations(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserOrganisationsProfile(
      options?: any
    ): AxiosPromise<Array<OrganisationProfileModel>> {
      return localVarFp
        .getCurrentUserOrganisationsProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get organisation license limits and current
     * @param {GetLicenceLimitsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLicenceLimits(
      query?: GetLicenceLimitsQuery,
      options?: any
    ): AxiosPromise<GetLicenceLimitsModel> {
      return localVarFp
        .getLicenceLimits(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationProfileById(
      organisationId: number,
      options?: any
    ): AxiosPromise<OrganisationProfileModel> {
      return localVarFp
        .getOrganisationProfileById(organisationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tier lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTierLookups(options?: any): AxiosPromise<GetTierLookupsModel> {
      return localVarFp
        .getTierLookups(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Remove All Organisation Users
     * @param {RemoveAllOrganisationUsersCommand} [removeAllOrganisationUsersCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAllOrganisationUsers(
      removeAllOrganisationUsersCommand?: RemoveAllOrganisationUsersCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .removeAllOrganisationUsers(removeAllOrganisationUsersCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Remove User
     * @param {RemoveOrganisationUserCommand} [removeOrganisationUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganisationUser(
      removeOrganisationUserCommand?: RemoveOrganisationUserCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .removeOrganisationUser(removeOrganisationUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Rename Organisation
     * @param {RenameOrganisationCommand} [renameOrganisationCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameOrganisation(
      renameOrganisationCommand?: RenameOrganisationCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .renameOrganisation(renameOrganisationCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Licence
     * @param {UpdateLicenceCommand} [updateLicenceCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLicence(
      updateLicenceCommand?: UpdateLicenceCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .updateLicence(updateLicenceCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Licence Dates
     * @param {UpdateLicenceDatesCommand} [updateLicenceDatesCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLicenceDates(
      updateLicenceDatesCommand?: UpdateLicenceDatesCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .updateLicenceDates(updateLicenceDatesCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload user csv file
     * @param {number} [organisationId]
     * @param {number} [roleId]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUserCsv(
      organisationId?: number,
      roleId?: number,
      file?: any,
      options?: any
    ): AxiosPromise<UploadUserCsvModel> {
      return localVarFp
        .uploadUserCsv(organisationId, roleId, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganisationApi - interface
 * @export
 * @interface OrganisationApi
 */
export interface OrganisationApiInterface {
  /**
   *
   * @summary Create Organisation User
   * @param {CreateOrganisationUserCommand} [createOrganisationUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  createOrganisationUser(
    createOrganisationUserCommand?: CreateOrganisationUserCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Extend Licence
   * @param {ExtendLicenceCommand} [extendLicenceCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  extendLicence(
    extendLicenceCommand?: ExtendLicenceCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Filters users in organisation.
   * @param {FilterOrganisationAllocationsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  filterOrganisationAllocations(
    query?: FilterOrganisationAllocationsQuery,
    options?: any
  ): AxiosPromise<FilterOrganisationAllocationsModelGridCollection>;

  /**
   *
   * @summary Filters organisations.
   * @param {FilterOrganisationQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  filterOrganisations(
    query?: FilterOrganisationQuery,
    options?: any
  ): AxiosPromise<FilterOrganisationModelGridCollection>;

  /**
   *
   * @summary Get user profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  getCurrentUserOrganisationsProfile(
    options?: any
  ): AxiosPromise<Array<OrganisationProfileModel>>;

  /**
   *
   * @summary Get organisation license limits and current
   * @param {GetLicenceLimitsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  getLicenceLimits(
    query?: GetLicenceLimitsQuery,
    options?: any
  ): AxiosPromise<GetLicenceLimitsModel>;

  /**
   *
   * @summary Get user profile
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  getOrganisationProfileById(
    organisationId: number,
    options?: any
  ): AxiosPromise<OrganisationProfileModel>;

  /**
   *
   * @summary Get Tier lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  getTierLookups(options?: any): AxiosPromise<GetTierLookupsModel>;

  /**
   *
   * @summary Remove All Organisation Users
   * @param {RemoveAllOrganisationUsersCommand} [removeAllOrganisationUsersCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  removeAllOrganisationUsers(
    removeAllOrganisationUsersCommand?: RemoveAllOrganisationUsersCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Remove User
   * @param {RemoveOrganisationUserCommand} [removeOrganisationUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  removeOrganisationUser(
    removeOrganisationUserCommand?: RemoveOrganisationUserCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Rename Organisation
   * @param {RenameOrganisationCommand} [renameOrganisationCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  renameOrganisation(
    renameOrganisationCommand?: RenameOrganisationCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Update Licence
   * @param {UpdateLicenceCommand} [updateLicenceCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  updateLicence(
    updateLicenceCommand?: UpdateLicenceCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Update Licence Dates
   * @param {UpdateLicenceDatesCommand} [updateLicenceDatesCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  updateLicenceDates(
    updateLicenceDatesCommand?: UpdateLicenceDatesCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Upload user csv file
   * @param {number} [organisationId]
   * @param {number} [roleId]
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApiInterface
   */
  uploadUserCsv(
    organisationId?: number,
    roleId?: number,
    file?: any,
    options?: any
  ): AxiosPromise<UploadUserCsvModel>;
}

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi
  extends BaseAPI
  implements OrganisationApiInterface
{
  /**
   *
   * @summary Create Organisation User
   * @param {CreateOrganisationUserCommand} [createOrganisationUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public createOrganisationUser(
    createOrganisationUserCommand?: CreateOrganisationUserCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .createOrganisationUser(createOrganisationUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Extend Licence
   * @param {ExtendLicenceCommand} [extendLicenceCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public extendLicence(
    extendLicenceCommand?: ExtendLicenceCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .extendLicence(extendLicenceCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters users in organisation.
   * @param {FilterOrganisationAllocationsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public filterOrganisationAllocations(
    query?: FilterOrganisationAllocationsQuery,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .filterOrganisationAllocations(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters organisations.
   * @param {FilterOrganisationQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public filterOrganisations(query?: FilterOrganisationQuery, options?: any) {
    return OrganisationApiFp(this.configuration)
      .filterOrganisations(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public getCurrentUserOrganisationsProfile(options?: any) {
    return OrganisationApiFp(this.configuration)
      .getCurrentUserOrganisationsProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get organisation license limits and current
   * @param {GetLicenceLimitsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public getLicenceLimits(query?: GetLicenceLimitsQuery, options?: any) {
    return OrganisationApiFp(this.configuration)
      .getLicenceLimits(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public getOrganisationProfileById(organisationId: number, options?: any) {
    return OrganisationApiFp(this.configuration)
      .getOrganisationProfileById(organisationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tier lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public getTierLookups(options?: any) {
    return OrganisationApiFp(this.configuration)
      .getTierLookups(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove All Organisation Users
   * @param {RemoveAllOrganisationUsersCommand} [removeAllOrganisationUsersCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public removeAllOrganisationUsers(
    removeAllOrganisationUsersCommand?: RemoveAllOrganisationUsersCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .removeAllOrganisationUsers(removeAllOrganisationUsersCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove User
   * @param {RemoveOrganisationUserCommand} [removeOrganisationUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public removeOrganisationUser(
    removeOrganisationUserCommand?: RemoveOrganisationUserCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .removeOrganisationUser(removeOrganisationUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Rename Organisation
   * @param {RenameOrganisationCommand} [renameOrganisationCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public renameOrganisation(
    renameOrganisationCommand?: RenameOrganisationCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .renameOrganisation(renameOrganisationCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Licence
   * @param {UpdateLicenceCommand} [updateLicenceCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public updateLicence(
    updateLicenceCommand?: UpdateLicenceCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .updateLicence(updateLicenceCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Licence Dates
   * @param {UpdateLicenceDatesCommand} [updateLicenceDatesCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public updateLicenceDates(
    updateLicenceDatesCommand?: UpdateLicenceDatesCommand,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .updateLicenceDates(updateLicenceDatesCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload user csv file
   * @param {number} [organisationId]
   * @param {number} [roleId]
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public uploadUserCsv(
    organisationId?: number,
    roleId?: number,
    file?: any,
    options?: any
  ) {
    return OrganisationApiFp(this.configuration)
      .uploadUserCsv(organisationId, roleId, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Super User
     * @param {CreateSuperUserCommand} [createSuperUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSuperUser: async (
      createSuperUserCommand?: CreateSuperUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/user/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSuperUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Purge User
     * @param {DisableUserCommand} [disableUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableUser: async (
      disableUserCommand?: DisableUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/user/disable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        disableUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters users in organisation.
     * @param {FilterUserQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers: async (
      query?: FilterUserQuery,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/user/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Purge User
     * @param {PurgeAndDisableUserCommand} [purgeAndDisableUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purgeAndDisableUser: async (
      purgeAndDisableUserCommand?: PurgeAndDisableUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/user/purge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        purgeAndDisableUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary User lookup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLookup: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/user/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Super User
     * @param {CreateSuperUserCommand} [createSuperUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSuperUser(
      createSuperUserCommand?: CreateSuperUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSuperUser(
        createSuperUserCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Purge User
     * @param {DisableUserCommand} [disableUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableUser(
      disableUserCommand?: DisableUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableUser(
        disableUserCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Filters users in organisation.
     * @param {FilterUserQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterUsers(
      query?: FilterUserQuery,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FilterUserModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterUsers(
        query,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Purge User
     * @param {PurgeAndDisableUserCommand} [purgeAndDisableUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purgeAndDisableUser(
      purgeAndDisableUserCommand?: PurgeAndDisableUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.purgeAndDisableUser(
          purgeAndDisableUserCommand,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary User lookup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLookup(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userLookup(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Create Super User
     * @param {CreateSuperUserCommand} [createSuperUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSuperUser(
      createSuperUserCommand?: CreateSuperUserCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .createSuperUser(createSuperUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Purge User
     * @param {DisableUserCommand} [disableUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableUser(
      disableUserCommand?: DisableUserCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .disableUser(disableUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Filters users in organisation.
     * @param {FilterUserQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers(
      query?: FilterUserQuery,
      options?: any
    ): AxiosPromise<FilterUserModelGridCollection> {
      return localVarFp
        .filterUsers(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Purge User
     * @param {PurgeAndDisableUserCommand} [purgeAndDisableUserCommand] command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purgeAndDisableUser(
      purgeAndDisableUserCommand?: PurgeAndDisableUserCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .purgeAndDisableUser(purgeAndDisableUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary User lookup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLookup(options?: any): AxiosPromise<void> {
      return localVarFp
        .userLookup(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
  /**
   *
   * @summary Create Super User
   * @param {CreateSuperUserCommand} [createSuperUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  createSuperUser(
    createSuperUserCommand?: CreateSuperUserCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Purge User
   * @param {DisableUserCommand} [disableUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  disableUser(
    disableUserCommand?: DisableUserCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary Filters users in organisation.
   * @param {FilterUserQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  filterUsers(
    query?: FilterUserQuery,
    options?: any
  ): AxiosPromise<FilterUserModelGridCollection>;

  /**
   *
   * @summary Purge User
   * @param {PurgeAndDisableUserCommand} [purgeAndDisableUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  purgeAndDisableUser(
    purgeAndDisableUserCommand?: PurgeAndDisableUserCommand,
    options?: any
  ): AxiosPromise<number>;

  /**
   *
   * @summary User lookup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  userLookup(options?: any): AxiosPromise<void>;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
  /**
   *
   * @summary Create Super User
   * @param {CreateSuperUserCommand} [createSuperUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public createSuperUser(
    createSuperUserCommand?: CreateSuperUserCommand,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .createSuperUser(createSuperUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Purge User
   * @param {DisableUserCommand} [disableUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public disableUser(disableUserCommand?: DisableUserCommand, options?: any) {
    return UserApiFp(this.configuration)
      .disableUser(disableUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters users in organisation.
   * @param {FilterUserQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public filterUsers(query?: FilterUserQuery, options?: any) {
    return UserApiFp(this.configuration)
      .filterUsers(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Purge User
   * @param {PurgeAndDisableUserCommand} [purgeAndDisableUserCommand] command data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public purgeAndDisableUser(
    purgeAndDisableUserCommand?: PurgeAndDisableUserCommand,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .purgeAndDisableUser(purgeAndDisableUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary User lookup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userLookup(options?: any) {
    return UserApiFp(this.configuration)
      .userLookup(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
