export const NotificationProvider = {
    provider: null as any,
    error: (message: string) => (NotificationProvider.provider)
        ? NotificationProvider.provider.error(message)
        : console.error(message),
    warning: (message: string) => (NotificationProvider.provider)
        ? NotificationProvider.provider.warning(message)
        : console.error(message),
    info: (message: string) => (NotificationProvider.provider)
        ? NotificationProvider.provider.info(message)
        : console.log(message),
    success: (message: string) => (NotificationProvider.provider)
        ? NotificationProvider.provider.success(message)
        : console.log(message),
}